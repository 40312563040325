//@ts-nocheck

import styled from 'styled-components/macro';

export const ScAuthorizationButtons = styled.div`
  display: flex;
  align-items: center;
  ${({ theme, authenticated }) =>
    theme.isWC &&
    !authenticated &&
    `
  background: linear-gradient(147.42deg, rgba(64, 72, 93, 0.4) 6.32%, rgba(96, 106, 130, 0.4) 92.25%), linear-gradient(128deg, #E6E7ED -79.65%, #F7F8FA 151.25%);
  background-blend-mode: soft-light, normal;
  box-shadow: -7px -7px 16px #FAFBFC, 4px 3px 19px #BDC1D1, inset -1px -1px 16px #F5F6FA, inset 1px 1px 16px #E9EAF2;
  border-radius: 15px;
  padding: 5px 6px 6px;
  & span {
    font-size: 16px;
    font-weight: 400;
  }
  `}
  & > a,
  & > button {
    margin-left: 16px;
    & > span > span {
      max-width: 108px;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: ${({ theme }) => theme.mobileSize}) {
        max-width: 200px;
      }
    }
    svg {
      width: auto;
      height: auto;
    }
  }
  .profileButton {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ theme }) =>
      theme.isWC &&
      `
    margin-left: 0;
    border: unset;
    box-shadow: none;
    `}
    svg path {
      fill: ${({ theme }) => theme.buttonColor};
      ${({ theme }) =>
        theme.isWC &&
        `
      fill: ${theme.colorPrimary};
      `}
    }
    &:hover {
      ${({ theme }) =>
        theme.isWC &&
        `
        &:not(.highlighted):not(:active) {
          span {
            color: ${theme.loginLightColor};
          }
          svg path {
            fill: ${theme.loginLightColor};
          }
        }
        `}
    }
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      ${({ theme }) =>
        !theme.isWC &&
        `
      background: ${theme.profileMobileButtonColor};
      `}
      width: 100%;
      order: 2;
      ${({ theme }) =>
        theme.isWC &&
        `
      order: 1;
      `}
      ${({ theme }) =>
        !theme.isWC &&
        `
      &:hover {
        background: ${theme.profileMobileLightButtonColor};
      }
      &:active {
        background: ${theme.profileMobileDarkButtonColor};
      }
      `}
    }
  }
  .loginButton {
    padding-left: 18px;
    padding-right: 18px;
    ${({ theme }) =>
      !theme.isWC &&
      `
    background-color: ${theme.loginBgColor};
    `}
    box-shadow: 0 0 0 1px ${({ theme }) => theme.loginBorderColor} inset;
    flex-shrink: 0;
    ${({ theme }) =>
      theme.isWC &&
      `
    margin-left: 6px;
    border: unset;
    `}
    span {
      color: ${({ theme }) => theme.loginColor};
    }
    svg path {
      fill: ${({ theme }) => theme.loginColor};
    }

    &:hover {
      ${({ theme }) =>
        !theme.isWC &&
        `
      box-shadow: 0 0 0 1px ${({ theme }) => theme.loginBorderLightColor} inset;
      color: ${({ theme }) => theme.loginLightColor};
      `}
      ${({ theme }) =>
        theme.isWC &&
        `
      &:not(.highlighted) {
          span {
            color: ${theme.loginLightColor};
          }
          svg path {
            fill: ${theme.loginLightColor};
          }
      }
      `}
    }
    &:active {
      ${({ theme }) =>
        !theme.isWC &&
        `
      background-color: ${({ theme }) => theme.loginDarkBgColor};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.loginBorderDarkColor} inset;
      color: ${({ theme }) => theme.loginDarkColor};
      `}
      ${({ theme }) =>
        theme.isWC &&
        `
      &:not(.highlighted) {
          span {
            color: ${theme.loginColor};
          }
          svg path {
            fill: ${theme.loginColor};
          }
      }
      `}
    }
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      box-shadow: 0 0 0 1px ${({ theme }) => theme.loginMobileBorderColor} inset;
      margin-left: 0;
      ${({ theme }) =>
        theme.isWC &&
        `
      margin-left: 6px;
      `}
      order: 1;
    }
  }
  .highlighted {
    ${({ theme }) =>
      theme.isWC &&
      `
    background: linear-gradient(129.67deg, rgba(122, 127, 133, 0.4) 14.52%, rgba(255, 255, 255, 0.4) 100%), linear-gradient(133.76deg, #E6E7ED -18.17%, #F7F8FA 140.27%);
    background-blend-mode: soft-light, normal;
    border: 1px solid ${theme.colorPrimary};
    & > span {
        color: ${theme.colorPrimary};
    }
    box-shadow: -2px -3px 8px #FAFAFC, 1px 1px 5px #B1B5C6, inset 1px 1px 3px #EBECF0;
    `}
  }
`;
