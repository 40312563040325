import { Theme } from '../contracts';
import { images } from './config-images';
import { paymentImages } from './config-pm-images';

export const theme: Theme = {
  defaultTitle: 'Bestcoin',
  name: 'bestcoin',
  basicWhite: '#fff',
  basicBackground: '#ffffff',
  basicPartBackground:
    'linear-gradient(212.61deg, #364B63 1.1%, #152334 98.42%)',
  basicGrey: '#C4C4C4',
  basicLightGrey: '#D2D2D2',
  basicInvisible: 'rgba(0, 0, 0, 0)',
  basicGreySelect: '#9996A7',
  mainBorderColor: '#9996A7',

  // colors
  mainTitleColor: '#152334',
  mainTextColor: '#152334',
  secondaryTextColor: '#5D5B67',
  promoBg: '#7F84A5',
  promoColor: '#ffffff',
  promoLinkColor: '#0359FD',
  linkColor: '#0359FD',
  linkHoverColor: '#4A89FF',
  loaderBg: 'rgba(255, 255, 255, 0.8)',
  buttonColor: '#FFFFFF',
  buttonBg: '#F7673F',
  buttonBgHover: '#FF7650',
  buttonBgPressed: '#ED552A',
  accordionSvgFill: '#F7673F',
  accordionSvgStroke: '#F7673F',
  tagBg: '#F7673F',
  tagColor: '#FFFFFF',
  tagPosLeft: '-10px',
  tagPosTop: '-5px',
  tagMobPosLeft: '-7px',
  tagMobPosTop: '16px',
  borderColor: '#F6F6F6',
  cardBg: '#F2F1F1',

  colorPrimary: '#F7673F',
  colorPrimaryLight: '#FF7650',
  colorPrimaryDark: '#ED552A',

  colorSecondary: '#0359FD',
  colorSecondaryLight: '#3A7EFF',
  colorSecondaryDark: '#004FE4',

  noticeColor: '#9996A7',
  noticeColor2: '#9996A7',

  colorGreen: '#68C205',
  colorRed: '#E02020',
  colorGrey: '#9996A7',
  colorYellow: '#DDB134',
  colorBlue: '#05018A',
  basicLightBtnGrey: '#F8F9FA',

  requisitesBg: '#F8F9FA',
  requisitesHoverBg: '#EEEEEE',
  requisitesPressedBg: '#E3E3E3',

  checkCryptoBg: '#152334',
  checkCryptoInfoAlertBg: '#F9F9F9',
  checkCryptoInfoAlertText: '#9996A7',
  checkCryptoStarColor: '#0359FD',
  checkCryptoStarReverseColor: '#ffffff',

  safeColor: '#69A43C',
  safeLightColor: '#EEF8F5',
  suspiciousColor: '#FFBC11',
  suspiciousLightColor: '#F8F3EE',
  danderColor: '#FF5050',
  danderLightColor: '#F8EEEE',

  // user status
  statusBronzeColor: '#FF9838',
  statusSilverColor: '#7292A1',
  statusGoldColor: '#F2CD2C',
  statusPlatinumColor: '#6B7893',
  statusDiamondColor: '#6B7893',

  // warning
  warningBg: '#FEF3EE',
  warningTextColor: '#9E2A0A',
  warningIconBg: '#FFDAC8',
  warningIconColor: '#F7673F',

  // input
  inputPlaceholderColor: '#9996A7',
  inputBorderColor: '#E8E8E8',
  focusInputColor: '#F7673F',
  inputAutocompleteBg: '#FFFFFF',

  // tabs
  tabsBg: '#FFFFFF',
  tabsColor: '#152334',
  tabsActiveBg: '#F7673F',
  tabsActiveColor: '#FFFFFF',
  tabsShadow: '0px 2px 15px rgba(0, 0, 0, 0.12)',
  tabsHoverColor: '#F7673F',

  // reserve tabs
  reserveActiveTabBg: '#F7673F',
  reserveActiveTabColor: '#ffffff',

  // notification
  notificationBg: '#152334',
  notificationBorder: 'none',

  // popup
  popupBg: '#152334',
  popupBorderColor: 'transparent',
  isSystemPopupBg: '#FFFFFF',
  isSystemPopupBannerBg: '#F7673F',
  isSystemPopupTitleColor: '#152334',
  isSystemPopupTextColor: '#5D5B67',

  // tooltips
  loginTooltipBorderColor: '#F7673F',
  loginTooltipTextColor: '#152334',
  loginTooltipBg: '#FFFFFF',
  tooltipBg: '#FFFFFF',
  paymentTooltipBg: '#152334',
  paymentTooltipBorder: 'none',
  infoTooltipBorderColor: '#152334',
  copyTooltipBg: '#152334',
  copyTooltipBorder: 'none',

  // profile button
  profileMobileButtonColor: '#F7673F',
  profileMobileLightButtonColor: '#FF7650',
  profileMobileDarkButtonColor: '#ED552A',

  // profile dropdown
  avatarTextColor: '#FFFFFF',
  profileDropdownBg: '#FFFFFF',
  profileDropdownHoverBg: '#EEEEEE',
  profileNewTagBg: '#F7673F',
  profileSoonTagBg: 'rgba(247, 103, 63, 0.1)',
  profileSoonTagColor: '#F7673F',
  profileSoonTagFontWeight: '400',
  profileSoonTagMobileBg: '#FFFFFF',
  profileSoonTagMobileColor: '#F7673F',
  profileHeaderBg: 'transparent',

  // language toggle button
  lgIconColor: '#7F84A5',
  lgTextColor: '#152334',
  lgMenuBg: '#FFFFFF',

  // login button
  loginBorderColor: '#152334',
  loginMobileBorderColor: '#152334',
  loginBorderLightColor: '#F7673F',
  loginBorderDarkColor: '#F7673F',
  loginColor: '#152334',
  loginLightColor: '#181E45',
  loginDarkColor: '#152334',
  loginBgColor: '#FFFFFF',
  loginLightBgColor: '#FFFFFF',
  loginDarkBgColor: '#FAFAFA',

  //outlined button
  outlineBorderColor: '#152334',

  // menu
  menuMobileBg: '#181E45',
  menuColor: '#152334',
  menuMobileColor: '#B6BCE0',
  menuMobileActiveColor: '#FFFFFF',
  menuHoverColor: '#0359FD',
  menuBurgerColor: '#152334',
  activeItemColor: '#0359FD',

  // sidebar
  sidebarActiveIconBg: 'transparent',
  sidebarActiveIconFill: '#0359FD',
  sidebarIconColor: '#9996A7',

  // table
  tableHeaderBg: '#F0F0F4',

  // modal
  modalBg: '#ffffff',
  basicTextModal: '#5D5B67',
  modalBorderColor: 'transparent',
  modalShadow: '0px 6px 16px rgba(0, 0, 0, 0.4)',

  // select
  selectBorderColor: '#C5C5C5',
  arrowColor: '#000000',
  selectOptionsBg: '#FFFFFF',
  selectOptionHoverBg: '#EEEEEE',
  selectOptionColor: '#152334',
  selectInactiveOptionColor: '#5D5B67',
  selectInactiveOptionBadgeColor: '#E0DFE4',
  selectInactiveOptionBadgeTextColor: '#5D5B67',
  selectNoMeansOptionBadgeColor: '#FFF0EC',
  selectNoMeansOptionBadgeTextColor: '#F7673F',
  selectHoverOptionColor: '#152334',

  // images
  errorIcon: images.errorIcon.path,
  successIcon: images.successIcon.path,
  infoIcon: images.infoIcon.path,
  crossArrowsIcon: `url(${images.crossArrowsIcon.path}) no-repeat 50% / contain`,
  mainBg: `#F7FAFC url(${images.mainBg.path}) no-repeat 50% / cover`,
  mainBgMobile: `url(${images.mainBgMobile.path}) no-repeat top / cover`,

  // border
  roundIconBorder: 'none',

  // border radius
  borderRadiusMain: '8px',
  borderRadiusSecondary: '12px',

  // fonts
  fontFamily: 'Ubuntu',
  mainFont: 'Ubuntu, sans-serif',
  activeItemWeight: '500',

  // exchange
  exchangeChildrenShadow: '0 14px 20px rgba(88,191,249,0.204682)',
  exchangeChildrenBg: '#ffffff',
  exchangeChildrenPseudo: 'none',
  exchangeBorderColor: 'transparent',
  exchangeSelectBg: 'transparent',

  // box shadows
  basicShadow: '0px 4px 24px rgba(0, 0, 0, 0.12)',

  // media
  smallMobileSize: '374px',
  mobileSize: '767px',
  tabletSize: '1024px',

  // jivo site
  jivoOff: `url(${images.jivoOff.path}) no-repeat 0 0`,
  jivoOffEn: `url(${images.jivoOffEn.path}) no-repeat 0 0`,
  jivoOn: `url(${images.jivoOn.path}) no-repeat 0 0`,
  jivoOnEn: `url(${images.jivoOnEn.path}) no-repeat 0 0`,
  jivoMobile: `url(${images.jivoMobile.path}) no-repeat 50% 50% !important`,

  // circle progress bar
  progressCircleDesktopSize: 170,
  progressCircleMobileSize: 140,
  progressCircleTrailColor: '#D7D7D7',

  isCrypto: true,

  images,
  paymentImages,
};

export default theme;
