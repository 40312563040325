import styled from 'styled-components/macro';
import { show } from 'components/globalStyles/animation';

import { ScButton } from 'components/button/styled';

interface ScBlockProps {
  half?: boolean;
  small?: boolean;
  loading?: boolean;
}

export const ScBlock = styled.div<ScBlockProps>`
  height: ${({ small }) => (small ? 'fit-content' : '566px')};
  width: ${({ small }) => (small ? 'fit-content' : '100%')};
  margin: ${({ small }) => (small ? '0 auto' : '0')};
  display: flex;
  flex-direction: ${({ half }) => (half ? 'row' : 'column')};
  min-height: 300px;
  min-width: 500px;
  padding: 36px;
  position: relative;
  background: ${({ theme }) => theme.basicBackground};
  box-shadow: ${({ theme }) => theme.basicShadow};
  ${({ theme }) =>
    theme.isWC &&
    `
  min-height: 566px;
  height: auto;
  background: linear-gradient(147.42deg, rgba(64, 72, 93, 0.4) 6.32%, rgba(96, 106, 130, 0.4) 92.25%), linear-gradient(128deg, #E6E7ED -79.65%, #F7F8FA 151.25%);
  background-blend-mode: soft-light, normal;
  box-shadow: -7px -7px 16px #FAFBFC, 4px 3px 19px #BDC1D1, inset -1px -1px 16px #F5F6FA, inset 1px 1px 16px #E9EAF2;
  `}
  border-radius: ${({ theme }) => theme.borderRadiusMain};
  transition: all ease 0.3s;
  animation: ${show} 1s ease;
  &:before {
    content: '';
    opacity: ${({ loading }) => (loading ? '1' : '0')};
    pointer-events: ${({ loading }) => (loading ? 'auto' : 'none')};
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: ${({ theme }) => theme.loaderBg};
    border-radius: 10px;
    top: 0;
    left: 0;
    transition: all ease 0.3s;
  }
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    padding: 13px 20px 20px 20px;
    min-width: initial;
    width: 100%;
    height: initial;
    min-height: 0;
    flex-direction: column;
  }
`;

export const ScBlockPart = styled.div<{ loading?: boolean }>`
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    width: 100%;
  }
  &:before {
    content: '';
    opacity: ${({ loading }) => (loading ? '1' : '0')};
    pointer-events: ${({ loading }) => (loading ? 'auto' : 'none')};
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: ${({ theme }) => theme.loaderBg};
    border-radius: 10px;
    top: 0;
    left: 0;
    transition: all ease 0.3s;
  }
`;

interface ScBlockTitleProps {
  small?: boolean;
}

export const ScBlockTitle = styled.h2<ScBlockTitleProps>`
  font: 600 ${({ small }) => (small ? '24px/36px' : '36px/41px')}
    ${({ theme }) => theme.mainFont};
  ${({ theme }) =>
    theme.isWC &&
    `
  font-weight: 700;
  line-height: 42px;
  `}
  color: ${({ theme }) => theme.mainTitleColor};
  letter-spacing: 0.105882px;
  margin-bottom: ${({ small }) => (small ? '8px' : '36px')};

  small {
    color: ${({ theme }) => theme.noticeColor2};
    font-size: 14px;
    font-weight: 300;
  }
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
    small {
      line-height: 14px;
    }
  }
`;
export const ScBlockSubTitle = styled.h4`
  font: 500 18px/20px ${({ theme }) => theme.mainFont};
  color: ${({ theme }) => theme.mainTitleColor};
  margin-bottom: 20px;
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    font-size: 16px;
    line-height: 18px;
  }
`;
export const ScContent = styled.div`
  display: flex;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.mobileSize}) {
    flex: 1;
  }
`;

export const ScErrorBlock = styled(ScBlock)`
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.mainTextColor};

  p {
    font-size: 16px;
    line-height: 20px;
    margin: 26px;
    text-align: center;
  }
`;
export const ScErrorBlockContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 36px 0;
  color: ${({ theme }) => theme.mainTextColor};
  p {
    margin: 20px auto;
  }
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    svg {
      max-width: 168px;
      height: auto;
      margin-bottom: 41px;
    }
    h3 {
      font-size: 28px;
      line-height: 32px;
      margin: 0 0 12px 0;
    }
    p {
      text-align: center;
      margin: 0 0 36px 0;
    }
    ${ScButton} {
      width: 100%;
      font-size: 18px;
      line-height: 21px;
    }
  }
`;

export const ScBlockLoading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(${({ theme }) => theme.images.loader.path}) no-repeat 50% 50%;
  background-size: 62px 62px;
`;

export const ScEmptyTableBlock = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 600px;
  color: ${({ theme }) => theme.mainTextColor};
  h2 {
    font-size: 48px;
    line-height: 55px;
    margin-bottom: 13px;
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      font-weight: 500;
      font-size: 32px;
      line-height: 37px;
      margin-bottom: 20px;
    }
  }
  p {
    font-size: 16px;
    line-height: 20px;
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      font-size: 14px;
    }
  }
`;
