import * as React from 'react';
import { SVGProps } from 'react';

const SvgInfoIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.333 3.667h1.334V5H6.333V3.667Zm0 2.666h1.334v4H6.333v-4Zm.667-6A6.67 6.67 0 0 0 .333 7 6.669 6.669 0 0 0 7 13.667 6.669 6.669 0 0 0 13.667 7 6.669 6.669 0 0 0 7 .333Zm0 12A5.34 5.34 0 0 1 1.667 7 5.34 5.34 0 0 1 7 1.667 5.34 5.34 0 0 1 12.333 7 5.34 5.34 0 0 1 7 12.333Z"
        fill="#0359FD"
      />
    </svg>
  );
};

export default SvgInfoIcon;
