import styled from 'styled-components/macro';

export const ScBurger = styled.div`
  @media (min-width: ${({ theme }) => theme.mobileSize}) {
    display: none;
  }
  .burger {
    display: block;
    height: 17px;
    width: 22px;
    svg {
      height: 100%;
      width: 100%;
      path {
        stroke: ${({ theme }) => theme.menuBurgerColor};
      }
    }
  }
`;

export const ScMobileMenu = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 20px 36px 36px 36px;
  background: ${({ theme }) => theme.menuMobileBg};
  overflow: auto;
  .close {
    width: 20px;
    height: 20px;
    position: relative;
    left: -20px;
    path {
      fill: ${({ theme }) => theme.menuMobileColor};
    }
  }

  .menu {
    margin: 15px 0 20px 0;
    & > li {
      .defaultIcon {
        width: 7px;
        height: 7px;
        background-color: ${({ theme }) => theme.menuColor};
        border-radius: 50%;
        display: inline-block;
        margin-right: 21px;
      }
      a {
        padding: 20px 0;
        &.active {
          span {
            color: ${({ theme }) => theme.menuMobileActiveColor};
            ${({ theme }) =>
              theme.isWC &&
              `
            font-weight: 900;
            `}
          }
          svg,
          path {
            fill: ${({ theme }) => theme.menuMobileActiveColor};
          }
          [class$='stroke-color'] {
            stroke: ${({ theme }) => theme.menuMobileActiveColor};
          }
        }
        ${({ theme }) =>
          theme.isWC &&
          `
        svg path {
            stroke-width: 0.5;
        }
        &.active {
            stroke: ${theme.menuMobileColor};
        }
        &.active.icon_stroke {
            &--big {
                & svg path {
                    stroke-width: 1.5;
                }
            }
            &--normal {
                & svg path {
                    stroke-width: 1;
                }
            }
            &--small {
                & svg path {
                    stroke-width: 1;
                }
            }
        }
        `}
      }
      span {
        color: ${({ theme }) => theme.menuMobileColor};
        font: 500 20px / 23px ${({ theme }) => theme.mainFont};
        ${({ theme }) =>
          theme.isWC &&
          `
        font: 400 23px / 27px ${theme.mainFont};
        `}
      }
      svg,
      path {
        fill: ${({ theme }) => theme.menuMobileColor};
      }
      [class$='stroke-color'] {
        stroke: ${({ theme }) => theme.menuMobileColor};
      }
      svg {
        margin-right: 26px;
      }
    }
  }
`;
