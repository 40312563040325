import styled, { css } from 'styled-components/macro';
import { BonusRangesName } from 'api/bonus';

const handleRangeName = (name: BonusRangesName, theme: any) => {
  switch (name) {
    case BonusRangesName.BRONZE:
      return css`
        color: ${theme.statusBronzeColor};
        background: ${theme.statusBronzeColor}1A;
      `;
    case BonusRangesName.SILVER:
      return css`
        color: ${theme.statusSilverColor};
        background: ${theme.statusSilverColor}1A;
      `;
    case BonusRangesName.GOLD:
      return css`
        color: ${theme.statusGoldColor};
        background: ${theme.statusGoldColor}1A;
      `;
    case BonusRangesName.PLATINUM:
      return css`
        color: ${theme.statusPlatinumColor};
        background: ${theme.statusPlatinumColor}1A;
      `;
    case BonusRangesName.DIAMOND:
      return css`
        color: ${theme.statusDiamondColor};
        background: ${theme.statusDiamondColor}1A;
      `;
    default:
      return css`
        color: ${theme.statusBronzeColor};
        background: ${theme.statusBronzeColor}1A;
      `;
  }
};

interface ScUserStatusTagProps {
  name: BonusRangesName;
}

export const ScUserStatusTag = styled.div<ScUserStatusTagProps>`
  display: inline-block;
  border-radius: 4px;
  font-size: 11px;
  line-height: 13px;
  margin-top: 4px;
  padding: 4px;
  ${({ name, theme }) => handleRangeName(name, theme)};
`;
