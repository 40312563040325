import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export function useMount() {
  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return isMounted;
}

export function useMutable<T>(value: T) {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  });

  return ref;
}

export function useOnUpdateEffect(
  effect: EffectCallback,
  deps?: DependencyList
) {
  const mountRender = useRef(true);

  function effectHandler() {
    if (mountRender.current) {
      mountRender.current = false;
      return;
    }

    return effect();
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectHandler, deps);
}
