import React from 'react';

import { ImageMap } from 'themes/contracts';
import { TFunction } from 'i18next';

export const getProfileItems = (
  images: ImageMap,
  t: TFunction,
  isCrypto: boolean,
  isBSorRCorWC?: boolean
) => {
  const cryptoItems = isCrypto
    ? [
        {
          content: t(`common:titles.${process.env.REACT_APP_THEME}.crypto`, {
            defaultValue: t('common:titles.crypto', {
              defaultValue: t('common:titles.crypto'),
            }),
          }),
          href: '/profile/crypto-check',
          image: <images.cryptoCheckIcon.component />,
          classNames: isBSorRCorWC ? ['crypto'] : ['crypto', 'new'],
        },
      ]
    : [];

  return [
    {
      content: t(`common:titles.${process.env.REACT_APP_THEME}.dashboard`, {
        defaultValue: t('common:titles.dashboard'),
      }),
      href: '/profile/dashboard',
      image: <images.dashboardIcon.component />,
      classNames: ['dashboard', 'soon'],
    },
    {
      content: t(`common:titles.${process.env.REACT_APP_THEME}.info`, {
        defaultValue: t('common:titles.info'),
      }),
      href: '/profile/info',
      image: <images.profileIcon.component />,
      classNames: [],
    },
    {
      content: t(`common:titles.${process.env.REACT_APP_THEME}.bids`, {
        defaultValue: t('common:titles.bids'),
      }),
      href: '/profile/bids',
      image: <images.exchangeHistoryIcon.component />,
      classNames: [],
    },
    ...cryptoItems,
    {
      content: t(`common:titles.${process.env.REACT_APP_THEME}.bonus`, {
        defaultValue: t('common:titles.bonus'),
      }),
      href: '/profile/bonus',
      image: <images.bonusIcon.component />,
      classNames:
        isBSorRCorWC || process.env.THEME === 'flychange' ? [''] : ['new'],
    },
    {
      content: t(`common:titles.${process.env.REACT_APP_THEME}.partners`, {
        defaultValue: t('common:titles.partners'),
      }),
      href: '/partner/info',
      image: <images.partnersIcon.component />,
      classNames: [],
    },
    {
      content: t(`common:titles.${process.env.REACT_APP_THEME}.settings`, {
        defaultValue: t('common:titles.settings'),
      }),
      href: '/profile/settings',
      image: <images.settingsIcon.component />,
      classNames: ['soon'],
    },
  ];
};
