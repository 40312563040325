import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';

export function useLanguageChange() {
  const { i18n } = useTranslation();

  const [pending, setPending] = useState(false);

  const changeLng = useCallback(
    async (lng: string) => {
      setPending(true);

      try {
        await i18n.changeLanguage(lng);
      } finally {
        setPending(false);
      }
    },
    [i18n]
  );

  return [changeLng, pending] as const;
}
