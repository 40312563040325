import styled from 'styled-components/macro';

export const ScScheduleBlock = styled.div`
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
  }
  .item {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.mainTextColor};
    & > svg {
      margin-right: 12px;
      vertical-align: middle;
      path {
        fill: ${({ theme }) => theme.buttonBg};
      }
    }
    & + .item {
      margin-top: 4px;
    }
  }
`;
