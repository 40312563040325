import { factory, RequestWithRecaptcha } from 'api/base';
import { snakify } from 'helpers/api';
import { TwoFactorAction, TwoFactorMethod } from 'api/auth';
import { shouldMock, mockRequestWith } from './mocks';
import { MOCKS } from './mocks.user';

export interface EditUserParams {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  phone?: string;
  telegram?: string;
  password?: string;
  email?: string;
  requisites?: {
    [pmId: string]: {
      [fieldName: string]: string | null | undefined;
    };
  };
}

export interface CurrentUser {
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  phone: string | null;
  telegram: string | null;
  requisites: {
    [pmId: string]: {
      [fieldName: string]: string;
    };
  };
  authActions: { [T in TwoFactorAction]: boolean };
  authMethod: TwoFactorMethod.GCODE;
  turnover: number;
  bonusBalance: number;
  bonusCrystalRate: number;
}

export const editUser = factory(
  (r) => (params: EditUserParams) => r<null>('put', '/user', params)
);

export const getCurrentUser = factory((r) => () => {
  if (shouldMock()) {
    return mockRequestWith(MOCKS['/user']);
  }
  return r<CurrentUser>('get', '/user');
});

export interface FeedbackForm extends RequestWithRecaptcha {
  subject: string;
  body: string;
}

export const feedback = factory(
  (r) => (form: FeedbackForm) => r<null>('post', '/feedback', snakify(form))
);
