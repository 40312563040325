import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Router, View } from 'react-navi';
import { lazy, mount, redirect } from 'navi';
import * as Sentry from '@sentry/react';

import { Layout } from 'components/Layout';
import { AuthServiceContext } from 'modules/authService/context';
import { useTheme } from 'hooks/theme';
import { getLoggedEmail } from 'helpers/auth';

function redirectFromPathLanguage(lng: string) {
  return redirect(
    (request) => {
      let path = request.path || '/';

      if (request.search) {
        if (!request.query.hasOwnProperty('lng')) {
          path += request.search + `&lng=${lng}`;
        }
      } else {
        path += `?lng=${lng}`;
      }

      return path + request.hash;
    },
    { exact: false }
  );
}

let rawRoutes = {};

rawRoutes = {
  '/ru': redirectFromPathLanguage('ru'),
  '/en': redirectFromPathLanguage('en'),

  '/': lazy(() => import('modules/exchange/route')),
  '/profile/bids/confirmation': lazy(
    () => import('modules/exchangeConfirmation/route')
  ),
  '/reserve': lazy(() => import('modules/reserve/route')),
  '/bonus': lazy(() => import('modules/bonus/route')),
  '/faq': lazy(() => import('modules/faq/route')),
  '/contacts': lazy(() => import('modules/contacts/route')),
  '/affiliate': lazy(() => import('modules/affiliate/route')),
  '/profile': lazy(() => import('modules/profile/route')),
  '/partner': lazy(() => import('modules/partner/route')),
  '/terms-of-use': lazy(() => import('modules/termsOfUse/route')),
  '/privacy': lazy(() => import('modules/privacy/route')),
  '/newsletters': lazy(() => import('modules/newsletters/route')),
  '/feedback': lazy(() => import('modules/feedbackForm/route')),
  '/login': lazy(() => import('modules/login/route')),
  '/registration': lazy(() => import('modules/registration/route')),
  '/perform_recovery': redirect((req) => `/recovery/perform${req.search}`),
  '/recovery': lazy(() => import('modules/recovery/route')),
  '/user': lazy(() => import('modules/user/route')),
  '/crypto/pay/:currency': lazy(() => import('modules/cryptoPayment/route')),
  '/refund/:token': lazy(() => import('modules/refund/route')),
  '/recalculate/:token': lazy(() => import('modules/recalculation/route')),
  '/resend/:token': lazy(() => import('modules/resendPayment/route')),
};

const isEOF = process.env.REACT_APP_API_EOL;

if (isEOF) {
  rawRoutes = {
    '/ru': redirectFromPathLanguage('ru'),
    '/en': redirectFromPathLanguage('en'),
    '/': lazy(() => import('modules/eol/route')),
    '*': redirect('/', { exact: false }),
  };
}

switch (process.env.REACT_APP_THEME) {
  case 'quickchange':
    rawRoutes = {
      ...rawRoutes,
      '/kyс_aml_policy': lazy(() => import('modules/aml/route')),
    };
    break;
  case 'flychange':
    rawRoutes = {
      ...rawRoutes,
      '/aml_kyс_policy': lazy(() => import('modules/aml/route')),
    };
    break;
  case 'whitechanger':
    rawRoutes = {
      ...rawRoutes,
      '/policy_kyс_aml': lazy(() => import('modules/aml/route')),
    };
    break;
}

const routes = mount(rawRoutes);

function setAuthenticationBreadcrumb(isAuthenticated: boolean) {
  Sentry.addBreadcrumb({
    category: 'auth',
    message: isAuthenticated ? 'User logged in' : 'User logged out',
    level: Sentry.Severity.Info,
  });
}

export const Routes = () => {
  const authService = useContext(AuthServiceContext);

  const theme = useTheme();

  const [isAuthenticated, setIsAuthenticated] = useState(
    authService.isAuthenticated
  );

  useEffect(() => {
    Sentry.setUser({ id: isAuthenticated ? getLoggedEmail() : 'anonymous' });
  }, [isAuthenticated]);

  useEffect(() => authService.subscribe(setIsAuthenticated), [authService]);
  useEffect(
    () => authService.subscribe(setAuthenticationBreadcrumb),
    [authService]
  );

  return (
    <Router routes={routes} context={{ isAuthenticated, authService, theme }}>
      <Layout>
        <Suspense fallback={null}>
          <View />
        </Suspense>
      </Layout>
    </Router>
  );
};
