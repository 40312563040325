import styled from 'styled-components/macro';
import { ScUserInitials } from 'components/userInitials/styled';

interface ScProfileDropdownProps {
  isOpen: boolean;
}

export const ScProfileDropdown = styled.div<ScProfileDropdownProps>`
  position: relative;
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.menuMobileColor}66;
    margin-top: 12px;
    padding-top: 32px;
  }
  .header {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    @media (min-width: ${({ theme }) => theme.mobileSize}) {
      background-color: ${({ theme }) => theme.profileHeaderBg};
      border-radius: 8px;
      padding: 12px;
    }
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      margin-bottom: 18px;
    }
    ${ScUserInitials} {
      margin-right: 8px;
      @media (max-width: ${({ theme }) => theme.mobileSize}) {
        margin-right: 12px;
      }
    }
    .email {
      font-size: 14px;
      line-height: 16px;
      color: ${({ theme }) => theme.mainTextColor};
      font-weight: 500;
      @media (max-width: ${({ theme }) => theme.mobileSize}) {
        color: ${({ theme }) => theme.menuMobileColor};
        font-weight: 700;
      }
    }
    .arrow svg {
      width: 20px;
      height: 20px;
      margin-left: 10px;
      transition-duration: 0.3s;
      transform: ${({ isOpen }) => (isOpen ? 'rotate(-180deg)' : 'rotate(0)')};
      path {
        fill: ${({ theme }) => theme.mainTextColor};
      }
      @media (max-width: ${({ theme }) => theme.mobileSize}) {
        display: none;
      }
    }
  }

  .profile-menu {
    @media (min-width: ${({ theme }) => theme.mobileSize}) {
      position: absolute;
      top: calc(100% + 15px);
      right: 0;
      z-index: 10;
      min-width: 254px;
      background: ${({ theme }) => theme.profileDropdownBg};
      box-shadow: ${({ theme }) => theme.basicShadow};
      border-radius: 8px;
      overflow: hidden;
      ${({ theme }) =>
        theme.isWC &&
        `
      background: linear-gradient(147.42deg, rgba(64, 72, 93, 0.4) 6.32%, rgba(96, 106, 130, 0.4) 92.25%), linear-gradient(128deg, #E6E7ED -79.65%, #F7F8FA 151.25%);
      background-blend-mode: soft-light, normal;
      box-shadow: -7px -7px 16px #FAFBFC, 4px 3px 19px #BDC1D1, inset -1px -1px 16px #F5F6FA, inset 1px 1px 16px #E9EAF2;
      border-radius: 15px;
      `}
    }

    & > li {
      a {
        display: flex;
        align-items: center;
        padding: 12px;
        font-size: 14px;
        line-height: 16px;
        color: ${({ theme }) => theme.mainTextColor};
        white-space: nowrap;
        svg {
          width: 20px;
          margin-right: 8px;
        }
        @media (max-width: ${({ theme }) => theme.mobileSize}) {
          font-size: 16px;
          line-height: 18px;
          color: ${({ theme }) => theme.menuMobileColor};
          padding: 14px 0;
          svg {
            display: none;
          }
        }
      }
      &:hover {
        @media (min-width: ${({ theme }) => theme.mobileSize}) {
          background-color: ${({ theme }) => theme.profileDropdownHoverBg};
        }
      }
      &:first-child {
        @media (min-width: ${({ theme }) => theme.mobileSize}) {
          border-bottom: 1px solid ${({ theme }) => theme.borderColor};
        }
      }
      &:last-child {
        padding: 14px 12px;
        font-size: 14px;
        line-height: 16px;
        color: ${({ theme }) => theme.secondaryTextColor};
        border-top: 1px solid ${({ theme }) => theme.borderColor};
        text-align: center;
        cursor: pointer;
        &.disabled {
          pointer-events: none;
        }
        @media (max-width: ${({ theme }) => theme.mobileSize}) {
          font-size: 16px;
          line-height: 18px;
          color: ${({ theme }) => theme.menuMobileColor};
          border-top: 1px solid ${({ theme }) => theme.menuMobileColor}66;
          text-align: left;
          padding: 28px 0 14px 0;
          margin-top: 18px;
        }
      }
      &.new > a:after,
      &.soon > a:after {
        display: inline-block;
        border-radius: 4px;
        margin-left: 17px;
        padding: 2px 4px;
        font-size: 10px;
        line-height: 11px;
        text-align: center;
        @media (max-width: ${({ theme }) => theme.mobileSize}) {
          margin-left: 8px;
        }
      }
      &.new > a:after {
        content: 'New';
        background-color: ${({ theme }) => theme.profileNewTagBg};
        color: #ffffff;
      }
      &.soon {
        svg,
        span {
          opacity: 0.4;
        }
        & > a:after {
          content: 'Soon';
          ${({ theme }) =>
            theme.isWC &&
            `
          font-size: 8px;
          `}
          background-color: ${({ theme }) => theme.profileSoonTagBg};
          color: ${({ theme }) => theme.profileSoonTagColor};
          @media (max-width: ${({ theme }) => theme.mobileSize}) {
            background-color: ${({ theme }) => theme.profileSoonTagMobileBg};
            color: ${({ theme }) => theme.profileSoonTagMobileColor};
          }
        }
      }
    }
  }
`;
