import React, { useContext } from 'react';
import { Link } from 'react-navi';

import { AuthorizationButtons } from 'components/authorizationButtons';
import { MobileMenu } from 'components/mobileMenu';
import { ScheduleBlock } from 'components/scheduleBlock';
import { UserInitials } from 'components/userInitials';
import { Icon } from 'components/UI/Icon';

import { AuthServiceContext } from 'modules/authService/context';
import { useTheme } from 'hooks/theme';

import { ScHeader } from './styled';

export const Header = () => {
  const { images } = useTheme();
  const authService = useContext(AuthServiceContext);

  return (
    <ScHeader>
      <MobileMenu />

      <div className="wrap">
        <div className="logo">
          <Link href="/">
            <images.logo.component />
          </Link>

          <Link href="/" className="mobile">
            <images.logoMobile.component />
          </Link>
        </div>
        <ScheduleBlock />
      </div>
      <Link href="/profile/info" className="accountLink">
        {authService.isAuthenticated ? (
          <UserInitials />
        ) : (
          <Icon name="AccountIcon" />
        )}
      </Link>
      <nav>
        <AuthorizationButtons />
      </nav>
    </ScHeader>
  );
};
