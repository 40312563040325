import { AxiosRequestConfig, AxiosResponseTransformer } from 'axios';

import { isObject } from 'helpers/base';

export function deepKeysTransformFactory(transform: (key: string) => string) {
  function deepKeysTransform<T extends { [key: string]: any } | any[]>(
    o: T,
    blacklists?: string[][]
  ): object {
    if (isObject(o)) {
      return Object.keys(o).reduce((previous, current) => {
        const transformedKey = transform(current);

        const keyValue = (o as { [key: string]: any })[current];

        if (blacklists) {
          const keyBlacklists = blacklists
            .filter((bl) => bl[0] === transformedKey)
            .map((bl) => bl.slice(1));

          if (keyBlacklists.find((bl) => bl.length === 0)) {
            previous[transformedKey] = keyValue;
          } else {
            previous[transformedKey] = deepKeysTransform(
              keyValue,
              keyBlacklists
            );
          }
        } else {
          previous[transformedKey] = deepKeysTransform(keyValue);
        }

        return previous;
      }, {} as { [key: string]: any });
    } else if (Array.isArray(o)) {
      return o.map((i) => {
        return deepKeysTransform(i, blacklists);
      });
    }

    return o;
  }

  return deepKeysTransform;
}

export enum ContentType {
  json = 'application/json',
  csv = 'text/csv; charset=utf-8',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  txt = 'text/plain; charset=utf-8',
}

export function getResponseCamelization(
  blacklists?: string[][]
): AxiosResponseTransformer {
  return function (data, headers) {
    if (
      headers != null &&
      headers['content-type'] != null &&
      headers['content-type'] === ContentType.json
    ) {
      let jsonResp;

      try {
        jsonResp = JSON.parse(data);
      } catch (e) {
        return {};
      }

      return camelize(jsonResp, blacklists);
    }

    return data;
  };
}

export const camelize = deepKeysTransformFactory(toCamel);
export const snakify = deepKeysTransformFactory(toSnake);

export function toCamel(s: string) {
  if (!s.includes('_')) return s;

  return s.replace(/([_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('_', '');
  });
}

export function toSnake(s: string) {
  return s
    .replace(/[\w]([A-Z])/g, function (m) {
      return m[0] + '_' + m[1];
    })
    .toLowerCase();
}

export interface AxiosResponse<T = any> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: AxiosRequestConfig;
  request?: any;
}
export interface ServerError {
  code: number;
  message: string;
  [extra: string]: any;
}

export type AxiosServerError = AxiosResponse<ServerError>;

export function isAxiosServerError(obj: any): obj is AxiosServerError {
  return (
    isObject(obj) &&
    obj.hasOwnProperty('data') &&
    obj.data.hasOwnProperty('code')
  );
}

export function parseErrorCode(e: any) {
  return isAxiosServerError(e) ? e.data.code : -1;
}

export function parseErrorMessage(e: any) {
  return isAxiosServerError(e) ? e.data.message : undefined;
}

export function parseErrorData(e: any) {
  if (!isAxiosServerError(e)) return;

  const { code, message, ...extra } = e.data;

  return extra;
}
