import styled from 'styled-components/macro';

export const ScTag = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.tagPosTop};
  left: ${({ theme }) => theme.tagPosLeft};
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    top: ${({ theme }) => theme.tagMobPosTop};
    left: ${({ theme }) => theme.tagMobPosLeft};
  }
  svg {
    background-color: transparent !important;
    margin: 0;
    padding: 0;
    position: relative;
    path {
      fill: ${({ theme }) => theme.tagBg} !important;
    }
  }
`;
