import * as React from 'react';
import { SVGProps } from 'react';

const SvgStarDollarGrayIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m24.83 9.655-6.942-1.009-3.103-6.292a.87.87 0 0 0-.397-.396.879.879 0 0 0-1.173.396l-3.103 6.292-6.943 1.01a.873.873 0 0 0-.484 1.492l5.023 4.897-1.187 6.915a.874.874 0 0 0 1.27.922L14 20.617l6.21 3.265a.874.874 0 0 0 1.269-.922l-1.187-6.915 5.023-4.897a.873.873 0 0 0 .254-.5.872.872 0 0 0-.738-.993Z"
        fill="#8B44E5"
      />
      <path
        className="star-dollar-gray-icon_svg__dollar"
        d="M16.624 13.998a1.719 1.719 0 0 0-.491-.561 3.26 3.26 0 0 0-.746-.407 12.208 12.208 0 0 0-.945-.329 11.761 11.761 0 0 1-.466-.15 1.97 1.97 0 0 1-.336-.147.666.666 0 0 1-.203-.17.356.356 0 0 1-.067-.217c0-.083.02-.154.062-.212a.458.458 0 0 1 .17-.141.892.892 0 0 1 .246-.079 1.77 1.77 0 0 1 .3-.024c.102 0 .204.014.304.042a1.82 1.82 0 0 1 .539.253c.079.054.15.106.215.156l.043.037c.045.035.086.065.123.089a.223.223 0 0 0 .124.036c.054 0 .109-.026.164-.076l.739-.73a.71.71 0 0 0 .094-.111.195.195 0 0 0 .032-.108c0-.038-.022-.085-.065-.139a1.322 1.322 0 0 0-.177-.172 2.692 2.692 0 0 0-.258-.185 5.557 5.557 0 0 0-.312-.184 3.224 3.224 0 0 0-.795-.292c-.082-.019-.166-.029-.249-.042V9.53a.166.166 0 0 0-.08-.143.277.277 0 0 0-.152-.054h-.908c-.071 0-.124.02-.16.059a.197.197 0 0 0-.053.138v.628c-.1.018-.205.033-.3.059a2.549 2.549 0 0 0-.81.373 1.708 1.708 0 0 0-.515.582 1.578 1.578 0 0 0-.182.755c0 .26.049.497.145.712a1.8 1.8 0 0 0 .435.588c.194.178.435.339.725.483.29.145.628.28 1.014.405.087.027.19.06.31.1.119.04.234.09.343.146.11.057.202.121.278.194a.338.338 0 0 1 .113.248.69.69 0 0 1-.04.238.485.485 0 0 1-.139.197.708.708 0 0 1-.263.136c-.11.034-.249.051-.413.051-.177 0-.347-.028-.51-.085a2.4 2.4 0 0 1-.485-.236 2.887 2.887 0 0 1-.293-.209 8.323 8.323 0 0 0-.21-.167.904.904 0 0 0-.116-.08.192.192 0 0 0-.092-.028c-.042 0-.096.023-.165.067l-.835.738c-.074.063-.111.12-.111.17 0 .048.037.107.111.18l.02.017.019.018.014.013c.155.158.333.306.534.443a4.112 4.112 0 0 0 1.353.602c.021.005.044.007.065.011v.657c0 .132.071.197.213.197h.908c.155 0 .232-.065.232-.197v-.643c.145-.024.289-.051.425-.092.342-.103.639-.249.892-.437s.451-.415.597-.682a1.84 1.84 0 0 0 .217-.892c0-.31-.059-.574-.176-.791Z"
        fill="#fff"
      />
    </svg>
  );
};

export default SvgStarDollarGrayIcon;
