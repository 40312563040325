import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Linkify, { Props as LinkifyProps } from 'react-linkify';

import { TooltipTypes, globalTooltipStore } from '../store';
import { ScGlobalTooltip } from 'components/globalTooltip/styled';

export const TOOLTIP_REMOVE_TIMEOUT = 7e3;

export const GlobalTooltip: FC = () => {
  const tooltip = globalTooltipStore((state) => state.tooltip);
  const remove = globalTooltipStore((state) => state.remove);

  useEffect(() => {
    if (tooltip && tooltip.animate) {
      const timeout = setTimeout(() => {
        remove();
      }, TOOLTIP_REMOVE_TIMEOUT);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [tooltip, remove]);

  const { t } = useTranslation();

  if (tooltip == null) return null;

  let body;
  if (tooltip.translate) {
    if (typeof tooltip.body === 'function') {
      body = tooltip.body(t);
    } else if (typeof tooltip.body === 'string') {
      body = t(tooltip.body);
    }
  } else {
    body = tooltip.body;
  }

  return (
    <ScGlobalTooltip
      key={tooltip.id}
      success={tooltip.type === TooltipTypes.SUCCESS}
      warning={tooltip.type === TooltipTypes.WARNING}
      info={tooltip.type === TooltipTypes.INFO}
      error={tooltip.type === TooltipTypes.ERROR}
      onClick={remove}
    >
      <Linkify componentDecorator={TooltipLink}>{body}</Linkify>
    </ScGlobalTooltip>
  );
};

const TooltipLink: LinkifyProps['componentDecorator'] = (href, text, key) => (
  <a
    key={key}
    href={href}
    onClick={(e) => {
      e.stopPropagation();
    }}
    target="_blank"
    rel="noreferrer noopener"
  >
    {text}
  </a>
);
