import * as React from 'react';
import { SVGProps } from 'react';

const SvgStarIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.923 10.029 12 5.947l-1.923 4.082-4.516.687 3.275 3.344-.751 4.582L12 16.486l3.915 2.155-.75-4.581 3.274-3.344-4.516-.687Zm7.458.428-4.165 4.252.838 5.11.146.893c.068.413-.197.805-.591.876a.692.692 0 0 1-.46-.076L12 18.678l-5.148 2.834a.698.698 0 0 1-.764-.057.754.754 0 0 1-.245-.328.792.792 0 0 1-.043-.415l.984-6.003-4.164-4.252a.778.778 0 0 1-.22-.535.78.78 0 0 1 .206-.54.713.713 0 0 1 .415-.221l5.756-.876 2.573-5.463a.714.714 0 0 1 1.3 0l2.574 5.463 5.756.876a.752.752 0 0 1 .612.862.771.771 0 0 1-.211.434Z"
        fill="#747890"
      />
    </svg>
  );
};

export default SvgStarIcon;
