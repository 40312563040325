import * as React from 'react';
import { SVGProps } from 'react';

const SvgEditIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m13.5 18.9 6.1-6.1 2.1 2.1-6.1 6.1h-2.1v-2.1Zm8.4-7.6 1.3 1.3c.2.2.2.5 0 .7l-1 1-2.1-2 1-1c.1-.1.2-.2.4-.2s.3.1.4.2ZM11.5 18h-7v-6h13.1l4.9-4.9V6c0-1.1-.9-2-2-2h-16c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h7v-2Zm-7-12h16v2h-16V6Z"
        fill="#747890"
      />
    </svg>
  );
};

export default SvgEditIcon;
