import React, { useMemo } from 'react';
import { Link } from 'react-navi';
import { useTranslation } from 'react-i18next';
import { availableLng } from 'lib/i18n';

import { LangToggle } from 'components/langToggle';
import { Icon } from 'components/UI/Icon';

import { ScFooter } from './styled';
import { useTheme } from '../../hooks/theme';

const reviewsLinkEnvParam = 'REACT_APP_BESTCHANGE_URL';

export const Footer = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { name } = useTheme();

  const hideLinks = process.env.REACT_APP_THEME !== 'flychange';

  const reviewsLink = useMemo(() => {
    const localeParam = reviewsLinkEnvParam + '_' + language.toUpperCase();

    return localeParam in process.env
      ? process.env[localeParam]
      : process.env[reviewsLinkEnvParam];
  }, [language]);

  let amlLink = '';

  switch (process.env.REACT_APP_THEME) {
    case 'quickchange':
      amlLink = '/kyс_aml_policy';
      break;
    case 'flychange':
      amlLink = '/aml_kyс_policy';
      break;
    case 'whitechanger':
      amlLink = '/policy_kyс_aml';
      break;
  }

  return (
    <ScFooter>
      <div
        style={hideLinks ? {} : { flexDirection: 'row-reverse' }}
        className="flex"
      >
        <span className="copyright">
          {t(`common:footer.${process.env.REACT_APP_THEME}.copyright`, {
            value: process.env.REACT_APP_TITLE,
            currentYear: new Date().getFullYear(),
            defaultValue: t('common:footer.copyright', {
              value: process.env.REACT_APP_TITLE,
            }),
          })}
        </span>

        {!hideLinks && reviewsLink ? (
          <a
            className="bestchange-link"
            target="_blank"
            rel="noreferrer noopener"
            href={reviewsLink}
          >
            <Icon width={77} name="BestchangeLogo" />
          </a>
        ) : null}

        {availableLng.length > 1 && <LangToggle />}
      </div>

      {hideLinks && (
        <div className="links">
          <div className="left">
            <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
              {process.env.REACT_APP_SUPPORT_EMAIL}
            </a>
            {reviewsLink ? (
              <a
                className="bestchange-link"
                target="_blank"
                rel="noreferrer noopener"
                href={reviewsLink}
              >
                <span>
                  {t(`common:footer.${process.env.REACT_APP_THEME}.reviews`, {
                    defaultValue: t('common:footer.reviews'),
                  })}
                </span>
                <Icon name="BestchangeLogo" />
              </a>
            ) : null}
          </div>
          <div className="right">
            <Link href="/terms-of-use">{t('common:footer.termsOfUse')}</Link>
            <a href={amlLink} className="aml-link">
              {t(`common:footer.${name}.aml`)}
            </a>
            <Link href="/privacy">{t('common:footer.privacy')}</Link>
          </div>
        </div>
      )}
    </ScFooter>
  );
};
