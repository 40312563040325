import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, FC } from 'react';
import { LinkProps } from 'react-navi/dist/types/Link';
import { Link } from 'react-navi';

import { useTheme } from 'hooks/theme';
import { ScButton, ScButtonProps, ScLoadingButtonIcon } from './styled';
import classNames from 'classnames';

interface BasicButtonProps extends ScButtonProps {
  loading?: boolean;
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  BasicButtonProps;

export const Button: FC<ButtonProps> = ({
  children,
  loading = false,
  ...props
}) => {
  const { images } = useTheme();

  return (
    <ScButton
      {...props}
      className={classNames(props.className, {
        loading: loading,
      })}
    >
      {loading ? (
        <ScLoadingButtonIcon>
          <images.loader.component />
        </ScLoadingButtonIcon>
      ) : null}
      <span> {children}</span>
    </ScButton>
  );
};

export type ButtonLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> &
  LinkProps &
  BasicButtonProps & { as?: any };

export const ButtonLink: FC<ButtonLinkProps> = ({
  children,
  loading = false,
  ...props
}) => {
  const { images } = useTheme();

  return (
    <ScButton
      as={Link}
      {...props}
      className={classNames(props.className, {
        loading: loading,
      })}
    >
      {loading ? (
        <ScLoadingButtonIcon>
          <images.loader.component />
        </ScLoadingButtonIcon>
      ) : null}
      <span> {children}</span>
    </ScButton>
  );
};
