import styled from 'styled-components/macro';
import { Link } from 'react-navi';

export const ScSidebarLink = styled(Link)`
  display: flex;
  align-items: center;
  position: relative;
  @media (min-width: ${({ theme }) => theme.mobileSize}) {
    &:hover {
      span {
        color: ${({ theme }) => theme.menuHoverColor};
      }
      svg,
      path {
        fill: ${({ theme }) => theme.menuHoverColor};
      }
      [class$='stroke-color'] {
        stroke: ${({ theme }) => theme.menuHoverColor};
      }
    }
  }
  span {
    color: ${({ theme }) => theme.menuColor};
    font: 400 14px / 16px ${({ theme }) => theme.mainFont};
  }
  ${({ theme }) =>
    theme.isWC &&
    `
  svg path {
    stroke-width: 0.5;
  }
  :hover:not(.active) svg path {
    stroke: ${theme.menuHoverColor};
  }
  &.active {
    svg path {
         stroke: #757E95;
    }
  }
  &.active.icon_stroke {
    &--big {
        & svg path {
            stroke-width: 1.5;
        }
    }
    &--normal {
        & svg path {
            stroke-width: 1;
        }
    }
    &--small {
        & svg path {
            stroke-width: 0.5;
        }
    }
  }
  `}
  svg {
    width: 24px;
    margin-right: 12px;
    box-sizing: content-box;
    border-radius: 50%;
    }
    &,
    path {
      fill: ${({ theme }) => theme.sidebarIconColor};
    }
    [class$='stroke-color'] {
      stroke: ${({ theme }) => theme.sidebarIconColor};
      stroke-width: 0.5;
    }
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      &,
      path {
        fill: #fff;
      }
      [class$='stroke-color'] {
        stroke: #fff;
      }
    }
  }
  &.active {
    @media (min-width: ${({ theme }) => theme.mobileSize}) {
      span {
        color: ${({ theme }) => theme.activeItemColor};
        font-weight: ${({ theme }) => theme.activeItemWeight};
      }
       svg {
        background-color: ${({ theme }) => theme.sidebarActiveIconBg};
        &, path {
          fill: ${({ theme }) => theme.sidebarActiveIconFill};
        }
        [class$='stroke-color'] {
          stroke: ${({ theme }) => theme.sidebarActiveIconFill};
        }
      }
    }
  }
  // FIXME: fix interface
` as any;

export interface ScButtonProps {
  small?: boolean;
  large?: boolean;
  primary?: boolean;
  secondary?: boolean;
  outlined?: boolean;
  loading?: boolean;
}

export const ScButton = styled.button<ScButtonProps>`
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ small, large }) =>
    small ? '9px 16px' : large ? '17px 28px' : '14px 28px'};
  box-sizing: border-box;
  max-height: 56px;
  border: ${({ outlined }) => (outlined ? '1.25px solid transparent' : 'none')};
  border-radius: ${({ theme, small }) =>
    small ? '4px' : theme.borderRadiusMain};
  text-align: center;
  transition: all ease 0.3s;
  position: relative;
  white-space: nowrap;
  ${({ large, small, theme }) =>
    large
      ? `font: 500 18px/21px ${theme.mainFont}`
      : small
      ? `font: 500 12px/14px ${theme.mainFont}`
      : `font: 400 14px/16px ${theme.mainFont}`};
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    padding: 17px 28px;
    font-weight: 500;
  }
  &.loading {
    & > span {
      opacity: 0;
    }
  }
  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme, outlined }) =>
      outlined ? theme.mainTextColor : theme.buttonColor};
  }
  svg {
    margin-right: 9px;
    &.loader-img {
      position: absolute;
      height: ${({ large }) => (large ? '21px' : '16px')};
      margin: 0;
      rect {
        fill: ${({ theme, outlined }) =>
          outlined ? theme.mainTextColor : '#fff'};
      }
    }
  }
  color: ${({ theme, outlined }) =>
    outlined ? theme.mainTextColor : theme.basicWhite};

  background: ${({ theme, secondary, outlined }) =>
    outlined
      ? 'transparent'
      : secondary
      ? theme.colorSecondary
      : theme.buttonBg};

  border-color: ${({ theme, small, outlined }) =>
    small ? '#DFDFDF' : outlined ? theme.mainTextColor : null};

  ${({ theme }) =>
    theme.isWC &&
    `
  background: linear-gradient(147.42deg, rgba(64, 72, 93, 0.4) 6.32%, rgba(96, 106, 130, 0.4) 92.25%), linear-gradient(128deg, #E9E9E9 -79.65%, #F7F8FA 151.25%);
  background-blend-mode: soft-light, normal;
  box-shadow: -10px 6px 32px #FAFCFC, 10px 10px 40px #BDC1D1, inset 1px 1px 9px #E6E7EB;
  border: 1px solid #5CCEFF;
  & > span {
    color: #6D7278;
    font-weight: 700;
  }
  `}

  @media (min-width: ${({ theme }) => theme.mobileSize}) {
    &:hover {
      background: ${({ theme, secondary, outlined }) =>
        outlined
          ? 'transparent'
          : secondary
          ? theme.colorSecondaryLight
          : theme.buttonBgHover};
      ${({ theme }) =>
        theme.isWC &&
        `
      background: linear-gradient(147.42deg, rgba(64, 72, 93, 0.4) 6.32%, rgba(96, 106, 130, 0.4) 92.25%), #C1C7D7;
      border-color: transparent;
      box-shadow: -10px -10px 42px #FAFCFC, 10px 10px 40px #BDC1D1;
      & > span {
        color: ${theme.basicWhite};
      }
      `}
    }
  }
  &:active {
    background: ${({ theme, secondary, outlined }) =>
      outlined
        ? 'transparent'
        : secondary
        ? theme.colorSecondaryDark
        : theme.buttonBgPressed};

    ${({ theme }) =>
      theme.isWC &&
      `
    background: linear-gradient(147.42deg, rgba(64, 72, 93, 0.4) 6.32%, rgba(96, 106, 130, 0.4) 92.25%), linear-gradient(128deg, #E9E9E9 -79.65%, #F7F8FA 151.25%);
    box-shadow: -10px -10px 42px #FAFCFC, 10px 10px 40px #BDC1D1, inset 1px 1px 9px #E6E7EB;
    border: 2.5px solid #5CCEFF;
    & > span {
        color: #6D7278;
    }
    `}
  }
  &:disabled {
    cursor: default;
    background: ${({ theme, secondary, outlined }) =>
      outlined ? 'transparent' : secondary ? theme.colorGrey : theme.colorGrey};
    border-color: ${({ theme, outlined }) =>
      outlined ? theme.colorGrey : null};
    color: ${({ theme, outlined }) => (outlined ? theme.colorGrey : null)};

    ${({ theme }) =>
      theme.isWC &&
      `
    background: linear-gradient(0deg, #40485D, #40485D), #D0D0D0;
    background-blend-mode: soft-light, normal;
    box-shadow: -10px -10px 42px #FAFCFC, 10px 10px 40px #BDC1D1;
    border-color: transparent;
    & > span {
        color: ${theme.basicWhite};
        opacity: 0.6;
    }
    `}
  }
`;

export const ScLoadingButtonIcon = styled.div`
  width: 28px;
  height: 28px;
  position: absolute;
  top: calc(50% - 14px);
  left: calc(50% - 14px);
`;
