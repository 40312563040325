import React, { FC, ReactNode } from 'react';
import { AuthService } from 'modules/authService/service';
import { setAuthService } from 'services/authService';
import { AuthServiceContext } from 'modules/authService/context';
import { isAuthenticated } from 'helpers/auth';

export const AuthServiceProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const authService = new AuthService(isAuthenticated());

  // makes auth service accessible for non react tree code
  setAuthService(authService);

  return (
    <AuthServiceContext.Provider value={authService}>
      {children}
    </AuthServiceContext.Provider>
  );
};
