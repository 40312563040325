import styled from 'styled-components/macro';

export const ScSidebar = styled.div`
  height: 566px;
  max-width: 186px;
  min-width: 167px;
  margin-right: 28px;
  border-radius: 0 0 10px 10px;
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${({ theme }) => theme.zIndexSidebar};
    width: 100%;
    margin-right: 0;
    &.active {
      display: block;
    }
  }
  ul {
    position: relative;
    margin-top: -20px;
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      margin-bottom: 76px;
    }
    li {
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      padding: 20px 5px 20px 24px;
      .defaultIcon {
        width: 7px;
        height: 7px;
        background-color: ${({ theme }) => theme.menuColor};
        border-radius: 50%;
        display: inline-block;
        margin-right: 21px;
      }
      @media (max-width: ${({ theme }) => theme.mobileSize}) {
        padding: 18px 5px 18px 40px;
      }
    }
  }

  .nav--mobile {
    display: none;
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      display: flex;
      flex-direction: column;
      padding: 0 36px;
    }
  }
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    .wrapper {
      margin-bottom: 76px;
    }
  }
`;
