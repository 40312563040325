import React, { FC } from 'react';
import { Severity } from '@sentry/types';
import { Routes } from 'router/routes';
import { ThemeProvider } from 'styled-components';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GlobalStyle } from 'components/globalStyles';
import { ErrorBoundary } from 'components/internal/errorBoundary';
import { InternalErrorBlock } from 'components/internalErrorBlock';
import { AuthServiceProvider } from 'modules/authService/provider';
import { Theme } from 'themes/contracts';

export interface AppProps {
  theme: Theme;
}

export const App: FC<AppProps> = ({ theme }) => {
  const app = (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ErrorBoundary
        fallback={<InternalErrorBlock />}
        severity={Severity.Fatal}
      >
        <AuthServiceProvider>
          <Routes />
        </AuthServiceProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );

  if (
    process.env.REACT_APP_RECAPTCHA_DISABLED === '1' ||
    process.env.REACT_APP_RECAPTCHA_SITEKEY_V3 == null
  ) {
    return app;
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITEKEY_V3}
    >
      {app}
    </GoogleReCaptchaProvider>
  );
};

export default App;
