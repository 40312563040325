import { createGlobalStyle } from 'styled-components';
import { Theme } from 'themes/contracts';
import { jivoAnimation } from 'components/globalStyles/animation';

export const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
@font-face {
    font-family: ${({ theme }) => theme.fontFamily};
    src: local(${({ theme }) => theme.fontFamily}),
          url('/fonts/light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: ${({ theme }) => theme.fontFamily};
    src: local(${({ theme }) => theme.fontFamily}),
          url('/fonts/regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: ${({ theme }) => theme.fontFamily};
    src: local(${({ theme }) => theme.fontFamily}),
          url('/fonts/medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: ${({ theme }) => theme.fontFamily};
    src: local(${({ theme }) => theme.fontFamily}),
        url('/fonts/bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

${({ theme }) =>
  theme.isWC &&
  `
@font-face {
    font-family: ${theme.fontFamily};
    src: local(${theme.fontFamily}),
        url('/fonts/black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}
`}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
section,
ul,
li {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  font: 400 16px / 18px ${({ theme }) => theme.mainFont};
}

img {
  max-width: 100%;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: ${({ theme }) => theme.linkColor};
  &:hover{
    color: ${({ theme }) => theme.linkHoverColor};
  }
  &:visited{
    color: ${({ theme }) => theme.linkColor};
  }
}

body {
  position: relative;
  //min-width: 375px;
  height: 100%;
  background-color: white;
}
button {
  font-family: ${({ theme }) => theme.mainFont};
  }

svg {
    flex: 0 0 auto;
  }
article{
  min-height: 100vh;
  background: ${({ theme }) => theme.mainBg};
  display: flex;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    background: ${({ theme }) => theme.mainBgMobile};
  }
}
main{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 1140px;
  @media (max-width: ${({ theme }) => theme.tabletSize}) {
      width: 100%;
    }
  & > section {
    width: 100%;
    display: flex;
    flex: 1;
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      max-height: 100%;
      width: 100%;
      padding: 16px 14px 0;
      margin-bottom: 52px;
    }
  }
}
.table-wrapper {
  display: flex;
  flex: 1 1 100%;
}

.hide{
  display: none;
}
//TODO: need refactoring
#jivo_chat_widget {
  display: none;
}

#jivo_custom_widget {
  position: fixed;
  right: -3px;
  bottom: 37%;
  width: 49px;
  z-index: 2147483699;
  cursor: pointer;
  height: 263px;
  transition: all 0.3s ease;
  animation: 0.3s linear ${jivoAnimation};
  @media only screen and (max-width: 1024px) {
    background: ${({ theme }) => theme.jivoMobile};
    background-size: cover;
    border-radius: 50%;
    box-shadow: 0 19px 38px 0 rgba(34, 36, 43, 0.3);
    width: 65px;
    height: 65px;
    bottom: 20px;
    right: 20px;
    z-index: 3;
  }
}
[class^='logoIconCloud'] {
   @media (max-width: ${({ theme }) => theme.mobileSize}) {
      display: none !important;
   }
}
html[lang=ru] #jivo_custom_widget {
   background: ${({ theme }) => theme.jivoOff};
   &.jivo_online {
    background: ${({ theme }) => theme.jivoOn};
  }
}

html[lang=en] #jivo_custom_widget {
   background: ${({ theme }) => theme.jivoOffEn};
   &.jivo_online {
    background: ${({ theme }) => theme.jivoOnEn};
  }
}

#jivo_custom_widget:hover {
  right: 0;
}

#jvlabelWrap,
.button_1O {
  display: none !important;
}

.grecaptcha-badge{
  visibility: hidden;
}
`;
