import * as React from 'react';
import { SVGProps } from 'react';

const SvgCopyIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.74 4.454a.733.733 0 0 0-.04-.198c-.008-.024-.014-.048-.024-.07a.747.747 0 0 0-.145-.215l-.001-.001L17.78.22 17.78.219a.749.749 0 0 0-.215-.145c-.022-.01-.044-.016-.067-.024a.739.739 0 0 0-.204-.041C17.278.008 17.265 0 17.25 0H6.75A.75.75 0 0 0 6 .75v3H3.75A.75.75 0 0 0 3 4.5v18.75c0 .414.336.75.75.75h13.5a.75.75 0 0 0 0-1.5H4.5V5.25H6v15c0 .414.336.75.75.75H21a.75.75 0 0 0 .75-.75V4.5c0-.016-.008-.03-.01-.046ZM18 2.56l1.19 1.189H18V2.56ZM7.5 19.5v-18h9v3c0 .414.336.75.75.75h3V19.5H7.5Z"
        fill="#372D6C"
      />
      <path
        d="M16.875 9.75h-6.75a.75.75 0 0 1 0-1.5h6.75a.75.75 0 0 1 0 1.5ZM16.875 12.75h-6.75a.75.75 0 0 1 0-1.5h6.75a.75.75 0 0 1 0 1.5ZM16.875 15.75h-6.75a.75.75 0 0 1 0-1.5h6.75a.75.75 0 0 1 0 1.5Z"
        fill="#372D6C"
      />
    </svg>
  );
};

export default SvgCopyIcon;
