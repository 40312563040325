import * as React from 'react';
import { SVGProps } from 'react';

const SvgArrowPagination = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={9}
      height={13}
      viewBox="0 0 9 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m1.684 1.72.733-.733L7.93 6.5l-5.513 5.513-.733-.732 4.403-4.413.367-.368-.367-.368L1.684 1.72Z"
        fill="#fff"
        stroke="#372D6C"
        strokeWidth={1.042}
      />
    </svg>
  );
};

export default SvgArrowPagination;
