import { BonusRanges, BonusOperation } from './bonus';

export const MOCKS = {
  '/bonus/rangs': [
    {
      coefficient: 0.025,
      id: 1,
      sumFrom: 0,
      sumTo: 500,
    },
    {
      coefficient: 0.05,
      id: 2,
      sumFrom: 500,
      sumTo: 50000,
    },
    {
      coefficient: 0.075,
      id: 3,
      sumFrom: 50000,
      sumTo: 500000,
    },
    {
      coefficient: 0.1,
      id: 4,
      sumFrom: 500000,
      sumTo: 1000000,
    },
    {
      coefficient: 0.125,
      id: 5,
      sumFrom: 1000000,
      sumTo: 10000000,
    },
  ] as BonusRanges[],
  '/bonus/operations': [] as BonusOperation[],
};
