import styled from 'styled-components/macro';

export const ScProgressBar = styled.div`
  pointer-events: none;
  .bar {
    background: ${({ theme }) => theme.colorPrimary};

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 4px;
  }

  /* Fancy blur effect */
  .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px ${({ theme }) => theme.colorPrimary},
      0 0 5px ${({ theme }) => theme.colorPrimary};
    opacity: 1;

    transform: rotate(3deg) translate(0px, -4px);
  }
`;
