import React, { FC, useEffect, useState } from 'react';
import { getNotification } from 'api/notification';
import { useApi } from 'hooks/api';

import { GiveawayPopup } from './popups/giveaway';
import { CryptoPopup } from './popups/crypto';
import { TelegramPopup } from './popups/telegram';
import { SystemPopup } from './popups/system';
import { BonusesPopup } from './popups/bonuses';

import { Icon } from 'components/UI/Icon';
import { ScNotificationPopup } from './styled';
import { PopupNotificationProps } from './popups/type';

interface PopupsProps {
  [propertyName: string]: (
    closePopup: PopupNotificationProps['closePopup'],
    content?: string | undefined
  ) => JSX.Element;
}

const popups: PopupsProps = {
  promoGiveaway: (closePopup) => <GiveawayPopup closePopup={closePopup} />,
  promoTelegram: (closePopup) => <TelegramPopup closePopup={closePopup} />,
  promoCrypto: (closePopup) => <CryptoPopup closePopup={closePopup} />,
  promoBonuses: (closePopup) => <BonusesPopup closePopup={closePopup} />,
  isSystem: (closePopup, content) => (
    <SystemPopup closePopup={closePopup} content={content} />
  ),
};

export const NotificationPopup: FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [requestNotification, { data: notificationData }] =
    useApi(getNotification);

  useEffect(() => {
    (async () => {
      const { error, response } = await requestNotification().request;

      if (error == null && response && response.data) {
        setIsModalVisible(true);
      }
    })();
  }, [requestNotification]);

  const closePopup = () => {
    setIsModalVisible(false);
  };

  if (
    notificationData == null ||
    notificationData.content == null ||
    !isModalVisible
  )
    return null;

  const currentPopupKey =
    Object.keys(popups).find(
      (key) =>
        notificationData &&
        notificationData.content &&
        key === notificationData.content
    ) || 'isSystem';

  return (
    <ScNotificationPopup type={currentPopupKey}>
      {popups[currentPopupKey](closePopup, notificationData.content)}
      <Icon name="CloseIcon" className="popup_close" onClick={closePopup} />
    </ScNotificationPopup>
  );
};
