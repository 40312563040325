import styled from 'styled-components/macro';

export const ScLangToggle = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.lgTextColor};
  .header {
    display: flex;
    align-items: center;
    cursor: pointer;
    & > svg:first-child {
      margin-right: 5px;
      path {
        fill: ${({ theme }) => theme.lgIconColor};
      }
    }
    & > svg:last-child {
      transition-duration: 0.3s;
      path {
        fill: ${({ theme }) => theme.lgTextColor};
      }
    }
    &.open > svg:last-child {
      transform: rotate(-180deg);
    }
    .title {
      font-weight: 500;
    }
  }
  .menu {
    position: absolute;
    bottom: calc(100% + 14px);
    z-index: 2;
    background: ${({ theme }) => theme.lgMenuBg};
    box-shadow: 0 4px 12px rgb(0 0 0 / 12%);
    border-radius: 10px;
    padding: 4px 0;
    min-width: 132px;
    @media (min-width: ${({ theme }) => theme.mobileSize}) {
      right: 0;
    }
    & > li {
      padding: 6px 16px;
      cursor: pointer;
      transition-duration: 0.3s;
      &:hover {
        opacity: 0.4;
      }
    }
  }
`;
