import { factory } from 'api/base';
import { shouldMock, mockRequestWith } from './mocks';
import { MOCKS } from './mocks.notification';

export interface Notification {
  id: string;
  content: string;
}

export const getNotification = factory((r) => () => {
  if (shouldMock()) {
    return mockRequestWith(MOCKS['/notifications/active']);
  }
  return r<Notification>('get', `/notifications/active`);
});
