import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from 'hooks/theme';
import { Button } from 'components/button';
import { PopupNotificationProps } from './type';

export const SystemPopup: FC<
  PopupNotificationProps & {
    content: string | undefined;
  }
> = ({ closePopup, content }) => {
  const { t } = useTranslation('common');
  const { images } = useTheme();
  return (
    <>
      <div className="popup_image">
        <images.notificationSystem.component />
      </div>
      <div className="popup_content">
        {content && (
          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        )}
        <div className="popup_ctrl">
          <Button onClick={closePopup}>
            {t('common:notifications.buttons.informed')}
          </Button>
        </div>
      </div>
    </>
  );
};
