export function mockRequestWith<Type>(mockData: Type) {
  return Promise.resolve({ data: mockData });
}

export function shouldMock() {
  return (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_MOCK_RESPONSES &&
    JSON.parse(process.env.REACT_APP_MOCK_RESPONSES)
  );
}
