import * as React from 'react';
import { SVGProps } from 'react';

const SvgSearch = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.383 10.562c0 3.96 3.221 7.18 7.18 7.18 1.592 0 3.065-.52 4.257-1.4l3.959 3.959a1.074 1.074 0 0 0 1.524 0c.42-.421.42-1.103 0-1.524l-3.96-3.96a7.143 7.143 0 0 0 1.401-4.255c0-3.96-3.22-7.181-7.18-7.181-3.96 0-7.181 3.221-7.181 7.18Zm10.812 3.472a5.01 5.01 0 0 0 1.395-3.472 5.032 5.032 0 0 0-5.026-5.027 5.032 5.032 0 0 0-5.027 5.027 5.032 5.032 0 0 0 5.027 5.026 5.01 5.01 0 0 0 3.472-1.395 1.083 1.083 0 0 1 .16-.16Z"
        fill="#9996A7"
      />
    </svg>
  );
};

export default SvgSearch;
