import React, { FC } from 'react';
import { LinkProps } from 'react-navi/dist/types/Link';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-navi';

import { ScSidebarLink } from 'components/button/styled';
import { useTheme } from 'hooks/theme';

import { ScSidebar } from './styled';
import { Tag } from 'components/tag';
import { Image } from 'themes/contracts';

interface SidebarLinkProps extends LinkProps {
  as?: any;
}

export const SidebarLink: FC<SidebarLinkProps> = ({ ...props }) => (
  <ScSidebarLink {...props} activeClassName="active" />
);

export const Sidebar = () => {
  const { t } = useTranslation();
  const { images, isCrypto, isBSorRC, isContactPage } = useTheme();

  function tSidebar(key: string) {
    return t(`common:sidebar.${process.env.REACT_APP_THEME}.${key}`, {
      defaultValue: t(`common:sidebar.${key}`),
    });
  }

  const cryptoItems = isCrypto
    ? [
        {
          href: '/profile/crypto-check',
          icon: images.cryptoCheckIcon,
          text: 'cryptoCheck',
          exact: true,
          as: Link,
          isNew: !isBSorRC && process.env.REACT_APP_THEME !== 'whitechanger',
          iconStrokeType: 'icon_stroke--small',
        },
      ]
    : [];

  const separateContactPage = isContactPage
    ? [
        {
          href: '/contacts',
          icon: images.infoIcon,
          text: 'contacts',
          exact: true,
          as: Link,
          isNew: false,
        },
      ]
    : [];

  const sidebarLinks: {
    href: string;
    icon: Image;
    text: string;
    exact: boolean;
    as?: any;
    isNew?: boolean;
    iconStrokeType?: string;
  }[] = [
    {
      href: '/',
      icon: images.exchangeIcon,
      text: 'exchange',
      exact: true,
      as: Link,
      isNew: false,
      iconStrokeType: 'icon_stroke--big',
    },
    {
      href: '/reserve',
      icon: images.courseIcon,
      text: 'reserve',
      exact: true,
      as: Link,
      isNew: false,
      iconStrokeType: 'icon_stroke--small',
    },
    {
      href: '/bonus',
      icon: images.bonusIcon,
      text: 'bonus',
      exact: true,
      as: Link,
      isNew: !(
        isBSorRC ||
        process.env.THEME === 'flychange' ||
        process.env.REACT_APP_THEME === 'whitechanger'
      ),
      iconStrokeType: 'icon_stroke--small',
    },
    ...cryptoItems,
    {
      href: '/partner',
      icon: images.partnersIcon,
      text: 'partner',
      exact: true,
      as: Link,
      iconStrokeType: 'icon_stroke--small',
    },
    {
      href: '/faq',
      icon: images.faqIcon,
      text: 'faq',
      exact: true,
      as: Link,
      isNew: false,
      iconStrokeType: 'icon_stroke--big',
    },
    ...separateContactPage,
  ];

  return (
    <ScSidebar>
      <ul>
        {sidebarLinks.map(
          ({ href, icon, text, exact, as, isNew, iconStrokeType }) => (
            <li key={href}>
              <SidebarLink
                as={as}
                href={href}
                exact={exact}
                className={iconStrokeType}
              >
                {icon ? <icon.component /> : <span className="defaultIcon" />}
                <span>{tSidebar(text)}</span>
                {isNew && <Tag />}
              </SidebarLink>
            </li>
          )
        )}
      </ul>
    </ScSidebar>
  );
};
