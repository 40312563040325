export const ENV = {
  IS_DEV: process.env.NODE_ENV === 'development',
  IS_PROD: process.env.NODE_ENV === 'production',

  PUBLIC_URL: process.env.PUBLIC_URL,
  BUILD_VERSION: process.env.BUILD_VERSION,
  APP_THEME: process.env.REACT_APP_THEME,
  APP_VERSION: process.env.APP_VERSION,
  APP_BUILD_DATE: process.env.APP_BUILD_DATE,
};
