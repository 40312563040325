import { CurrentUser } from './user';
export const MOCKS = {
  '/user': {
    authActions: { 0: true },
    authMethod: 0,
    bonusBalance: 0,
    bonusCoefficient: null,
    bonusCrystalRate: 100,
    expireAt: 1674206853471,
    turnover: 0,
    firstName: null,
    lastName: null,
    middleName: null,
    phone: null,
    telegram: null,
    requisites: {
      azaza: {
        kek: 'shmek',
      },
    },
  } as CurrentUser,
};
