import React, { useCallback, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useLanguageChange } from 'hooks/i18n';
import { useOutsideClickVisibility } from 'hooks/visibility';

import cx from 'classnames';
import { ScLangToggle } from './styled';
import { Icon } from 'components/UI/Icon';

export const LangToggle = forwardRef<HTMLDivElement>(() => {
  const [changeLang] = useLanguageChange();
  const { i18n } = useTranslation();

  const { ref, isComponentVisible, setIsComponentVisible } =
    useOutsideClickVisibility(false);

  const toggle = useCallback(
    () => void setIsComponentVisible((i) => !i),
    [setIsComponentVisible]
  );

  return (
    <ScLangToggle ref={ref} onClick={toggle}>
      <div className={cx('header', isComponentVisible && 'open')}>
        <Icon name="Sphere" />
        <span className="title">
          {i18n.language === 'ru' ? 'Русский' : 'English'}
        </span>
        <Icon name="Arrow" />
      </div>
      {isComponentVisible && (
        <ul className="menu">
          <li onClick={() => changeLang('ru')}>Русский</li>
          <li onClick={() => changeLang('en')}>English</li>
        </ul>
      )}
    </ScLangToggle>
  );
});
