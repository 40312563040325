import styled from 'styled-components/macro';

export const ScHeader = styled.header`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 32px 0 50px 0;
  position: relative;
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    padding: 16px;
    align-items: center;
    margin-bottom: 52px;
  }
  .wrap {
    display: flex;
    align-items: flex-end;
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      margin: 0 auto;
    }
  }
  .logo {
    width: auto;
    padding: 0 24px;
    min-width: 195px;
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      padding: 0;
      min-width: initial;
    }
    a {
      display: inline-block;
      @media (max-width: ${({ theme }) => theme.mobileSize}) {
        display: none;
      }
      &.mobile {
        display: none;
        @media (max-width: ${({ theme }) => theme.mobileSize}) {
          display: block;
        }
      }
    }
  }
  .accountLink {
    display: none;
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      display: block;
      svg > path {
        fill: ${({ theme }) => theme.menuBurgerColor};
      }
    }
  }

  nav {
    display: flex;
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      display: none;
    }
  }
`;
