import libAxios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import * as Sentry from '@sentry/react';

import { handleAuth } from 'helpers/auth';
import { getResponseCamelization } from 'helpers/api';
import { RecaptchaVersion } from 'helpers/constants';

// import { i18nInstance, fallbackLng } from 'i18n';

export type RequestMethod =
  | 'get'
  | 'post'
  | 'put'
  | 'delete'
  | 'head'
  | 'patch';

export type RequestContext = AxiosRequestConfig | void;

export type RequestFn<Args extends any[] = any, Return = any> = (
  this: RequestContext,
  ...args: Args
) => Promise<{ data: Return }>;

function getStaticGlobalHeaders() {
  const headers: { [name: string]: string } = {};

  if (process.env.NODE_ENV === 'development') {
    headers.Dev = '1';

    if (process.env.REACT_APP_DEV_DOMAIN) {
      headers['Dev-Domain'] = process.env.REACT_APP_DEV_DOMAIN;
    }

    if (process.env.REACT_APP_DEV_AUTHENTICATED_ACCOUNT) {
      headers['Dev-Authenticated-Account'] =
        process.env.REACT_APP_DEV_AUTHENTICATED_ACCOUNT;
    }

    if (process.env.REACT_APP_RECAPTCHA_DISABLED === '1') {
      headers['Dev-Recaptcha-Disabled'] = '1';
    }
  }

  return headers;
}

function getDynamicGlobalHeaders() {
  const headers: { [name: string]: string } = {};

  // headers.Lng = (i18nInstance && i18nInstance.language) || fallbackLng;

  return headers;
}

const axiosGlobalConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  timeout: parseInt(process.env.REACT_APP_REQUEST_TIMEOUT || '20') * 1000,
  headers: getStaticGlobalHeaders(),
  withCredentials: process.env.NODE_ENV === 'development',
};

axiosGlobalConfig.transformResponse = [getResponseCamelization()];
axiosGlobalConfig.paramsSerializer = (params) =>
  qs.stringify(params, { arrayFormat: 'repeat' });

const axios = libAxios.create(axiosGlobalConfig);

axios.interceptors.response.use(
  (response) => {
    handleAuth();
    return response;
  },
  (error) => {
    handleAuth();

    if (error.hasOwnProperty('response')) {
      if (error.response.status >= 500) {
        Sentry.addBreadcrumb({
          category: 'xhr',
          message: `Error status code ${error.response.status} for ${error.response.request.responseURL}`,
          level: Sentry.Severity.Warning,
          data: {
            status: error.response.status,
            data: error.response.data,
          },
        });

        Sentry.captureException(error);
      }

      return Promise.reject(error.response);
    }

    throw error;
  }
);

export function request<T = any>(
  this: AxiosRequestConfig | void,
  method: RequestMethod,
  uri: string,
  data?: object,
  functionScopedConfig?: AxiosRequestConfig
): AxiosPromise<T> {
  const conf: AxiosRequestConfig = {
    method,
    url: uri,
    [method === 'get' ? 'params' : 'data']: data,
    ...this,
    ...functionScopedConfig,
  };

  const dynamicGlobalHeaders = getDynamicGlobalHeaders();

  if (conf.headers == null) {
    conf.headers = dynamicGlobalHeaders;
  } else {
    conf.headers = { ...dynamicGlobalHeaders, ...conf.headers };
  }

  return axios(conf);
}

export function factory<Args extends any[], Return>(
  handler: (r: typeof request) => RequestFn<Args, Return>
) {
  return function (this: RequestContext, ...rest: Args) {
    const r = request.bind(this) as typeof request; // bind looses generic

    return handler(r)(...rest);
  };
}

export interface RequestWithRecaptcha {
  recaptcha: string;
  recaptchaVersion: RecaptchaVersion;
}
