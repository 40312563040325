//@ts-nocheck

import React, { FC, useContext, useState } from 'react';
import { useCurrentRoute } from 'react-navi';
import { useTranslation } from 'react-i18next';

import { AuthServiceContext } from 'modules/authService/context';
import { ButtonLink } from 'components/button';
import { ProfileDropdown } from 'components/profileDropdown';
import { Icon } from 'components/UI/Icon';

import { ScAuthorizationButtons } from './styled';

interface AuthorizationButtonsProps {
  handleOpenMenu?: () => void;
  isMobile?: boolean;
}

export const AuthorizationButtons: FC<AuthorizationButtonsProps> = ({
  handleOpenMenu,
  isMobile,
}) => {
  const authService = useContext(AuthServiceContext);
  const [highlightedButton, setHighlightedButton] = useState('reg');
  const {
    url: { href, pathname },
  } = useCurrentRoute();

  const next =
    pathname === '/login' || pathname === '/registration' ? null : href;

  const { t } = useTranslation();

  return (
    <ScAuthorizationButtons authenticated={authService.isAuthenticated}>
      {authService.isAuthenticated ? (
        <ProfileDropdown isMobile={isMobile} onOpenMenu={handleOpenMenu} />
      ) : (
        <>
          <ButtonLink
            className={
              'profileButton' +
              (highlightedButton === 'reg' ? ' highlighted' : '')
            }
            href="/registration"
            onClick={() => {
              setHighlightedButton('reg');
              handleOpenMenu && handleOpenMenu();
            }}
          >
            <Icon name="RegistrationIcon" />
            <span>
              {t(`common:header.${process.env.THEME}.signUp`, {
                defaultValue: t('common:header.signUp'),
              })}
            </span>
          </ButtonLink>

          <ButtonLink
            className={
              'loginButton' +
              (highlightedButton === 'login' ? ' highlighted' : '')
            }
            onClick={() => {
              setHighlightedButton('login');
              handleOpenMenu && handleOpenMenu();
            }}
            href={
              next == null
                ? '/login'
                : `/login?next=${encodeURIComponent(href)}`
            }
          >
            <Icon name="LoginIcon" />
            <span>
              {t(`common:header.${process.env.THEME}.login`, {
                defaultValue: t('common:header.login'),
              })}
            </span>
          </ButtonLink>
        </>
      )}
    </ScAuthorizationButtons>
  );
};
