import * as React from 'react';
import { SVGProps } from 'react';

const SvgWarningIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.874 3.908c-.834-1.441-2.914-1.441-3.748 0L1.85 14.749C1.016 16.191 2.056 18 3.725 18h12.55c1.669 0 2.709-1.81 1.874-3.25L11.874 3.907ZM10 11.167a.336.336 0 0 1-.333-.334V9.167c0-.183.15-.334.333-.334.182 0 .333.151.333.334v1.666a.336.336 0 0 1-.333.334ZM9.667 14.5v-.667h.666v.667h-.666Z"
        fill="#FF5050"
        stroke="#fff"
      />
    </svg>
  );
};

export default SvgWarningIcon;
