import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ScUserStatusTag } from './styled';
import { getBonusRanges, BonusRanges, BonusRangesName } from 'api/bonus';
import { useApi } from 'hooks/api';
import { USERS_STATUSES_AMOUNT } from 'helpers/constants';

interface UserStatusTagProps {
  turnover: number;
}

const RANGES_NAMES = [
  BonusRangesName.BRONZE,
  BonusRangesName.SILVER,
  BonusRangesName.GOLD,
  BonusRangesName.PLATINUM,
  BonusRangesName.DIAMOND,
];

export const UserStatusTag: FC<UserStatusTagProps> = ({ turnover }) => {
  const { t } = useTranslation('common');

  const [bonusRangesReq, { data: ranges }] = useApi(getBonusRanges);

  useEffect(() => {
    bonusRangesReq();
  }, [bonusRangesReq]);

  const currentRange = useMemo(() => {
    if (ranges == null) return;

    const rangesWithNames = ranges
      .slice(0, USERS_STATUSES_AMOUNT)
      .map((range, index) => ({
        name: RANGES_NAMES[index],
        ...range,
      }));

    return (
      rangesWithNames.find(
        (range: BonusRanges) =>
          turnover >= range.sumFrom && turnover < range.sumTo
      ) ||
      rangesWithNames[rangesWithNames.length - 1] ||
      null
    );
  }, [ranges, turnover]);

  return currentRange != null ? (
    <ScUserStatusTag name={currentRange.name}>
      {t(`userStatus.${currentRange.name}`)}
    </ScUserStatusTag>
  ) : null;
};
