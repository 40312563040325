import styled from 'styled-components/macro';

export const ScUserInitials = styled.div`
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.buttonBg};
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.avatarTextColor};
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
  }
`;
