import * as React from 'react';
import { SVGProps } from 'react';

const SvgAccountIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.782 22.726a3.448 3.448 0 0 1 3.443-3.444h8.036a3.448 3.448 0 0 1 3.443 3.444v1.147H8.782v-1.148Zm-2.296 0v3.443H26v-3.443a5.746 5.746 0 0 0-5.74-5.74h-8.035a5.746 5.746 0 0 0-5.739 5.74ZM16.243 7.803a2.873 2.873 0 0 1 2.87 2.87 2.873 2.873 0 0 1-2.87 2.87 2.873 2.873 0 0 1-2.87-2.87 2.873 2.873 0 0 1 2.87-2.87Zm0 8.036a5.171 5.171 0 0 0 5.165-5.166 5.172 5.172 0 0 0-5.165-5.165 5.172 5.172 0 0 0-5.166 5.165 5.171 5.171 0 0 0 5.166 5.166Z"
        fill="#8B44E5"
      />
    </svg>
  );
};

export default SvgAccountIcon;
