import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthorizationButtons } from 'components/authorizationButtons';

import { ScSidebarLink } from 'components/button/styled';
import { ScMobileMenu, ScBurger } from './styled';
import { LinkProps } from 'react-navi/dist/types/Link';
import { Tag } from 'components/tag';

import { Icon } from 'components/UI/Icon';
import { useTheme } from 'hooks/theme';
import { uuid } from 'helpers/base';
import { Link } from 'react-navi';
import { Image } from 'themes/contracts';

interface SidebarLinkProps extends LinkProps {
  as?: any;
}

export const SidebarLink: FC<SidebarLinkProps> = ({ ...props }) => (
  <ScSidebarLink {...props} activeClassName="active" />
);

export const MobileMenu = () => {
  const { t } = useTranslation();
  const { images, isCrypto, isBSorRC, isContactPage, isWC } = useTheme();
  const [open, handleOpenMenu] = useState(false);

  function tSidebar(key: string) {
    return t(`common:sidebar.${process.env.THEME}.${key}`, {
      defaultValue: t(`common:sidebar.${key}`),
    });
  }

  const cryptoItems = isCrypto
    ? [
        {
          href: '/profile/crypto-check',
          icon: images.cryptoCheckIcon,
          text: 'cryptoCheck',
          exact: true,
          as: Link,
          isNew: !isBSorRC && !isWC,
          iconStrokeType: 'icon_stroke--small',
        },
      ]
    : [];

  const separateContactPage = isContactPage
    ? [
        {
          href: '/contacts',
          icon: images.infoIcon,
          text: 'contacts',
          exact: true,
          as: Link,
          isNew: false,
        },
      ]
    : [];

  const items: {
    href: string;
    icon: Image;
    text: string;
    exact: boolean;
    as?: any;
    isNew?: boolean;
    iconStrokeType?: string;
  }[] = [
    {
      href: '/',
      icon: images.exchangeIcon,
      text: 'exchange',
      exact: true,
      as: Link,
      isNew: false,
      iconStrokeType: 'icon_stroke--big',
    },
    {
      href: '/reserve',
      icon: images.courseIcon,
      text: 'reserve',
      exact: true,
      as: Link,
      isNew: false,
      iconStrokeType: 'icon_stroke--small',
    },
    {
      href: '/bonus',
      icon: images.bonusIcon,
      text: 'bonus',
      exact: true,
      as: Link,
      isNew: !(isBSorRC || process.env.THEME === 'flychange' || isWC),
      iconStrokeType: 'icon_stroke--small',
    },
    ...cryptoItems,
    {
      href: '/partner',
      icon: images.partnersIcon,
      text: 'partner',
      exact: true,
      as: Link,
      iconStrokeType: 'icon_stroke--small',
    },
    {
      href: '/faq',
      icon: images.faqIcon,
      text: 'faq',
      exact: true,
      as: Link,
      isNew: false,
      iconStrokeType: 'icon_stroke--big',
    },
    ...separateContactPage,
  ];

  return (
    <>
      <ScBurger>
        <div className="burger" onClick={() => handleOpenMenu(true)}>
          <Icon name="BurgerIcon" />
        </div>
      </ScBurger>
      {open && (
        <ScMobileMenu>
          <div>
            <Icon
              name="CloseMobileIcon"
              className="close"
              onClick={() => handleOpenMenu(false)}
            />
            <ul className="menu" onClick={() => handleOpenMenu(false)}>
              {items.map(
                ({ href, icon, text, exact, as, isNew, iconStrokeType }) => {
                  return (
                    <li key={uuid()}>
                      <SidebarLink
                        as={as}
                        href={href}
                        exact={exact}
                        className={iconStrokeType}
                      >
                        {icon ? (
                          <icon.component />
                        ) : (
                          <span className="defaultIcon" />
                        )}
                        <span>{tSidebar(text)}</span>
                        {isNew && <Tag />}
                      </SidebarLink>
                    </li>
                  );
                }
              )}
            </ul>
          </div>
          <div>
            <AuthorizationButtons
              handleOpenMenu={() => handleOpenMenu(false)}
              isMobile
            />
          </div>
        </ScMobileMenu>
      )}
    </>
  );
};
