import React, { memo } from 'react';
import * as icons from 'assets/icons';
import { iconSizes } from '../Icon';

function Icon({ name, size, color, ...rest }: IconProps): JSX.Element {
  if (!icons[name]) {
    return <div className="text-red-primary p-1">⚠️error "{name}"</div>;
  }

  const Component = icons[name];
  const _size = size
    ? {
        width: iconSizes[size],
        height: iconSizes[size],
      }
    : null;

  return <Component data-testid="svg" {..._size} {...rest} color={color} />;
}

export default memo(Icon);
