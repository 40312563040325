import React, { FC } from 'react';

import { ErrorBlock } from 'components/block';
import { ScErrorBlock } from './styled';

export const InternalErrorBlock: FC = () => (
  <main>
    <ScErrorBlock>
      <ErrorBlock refresh={false} hardRefresh={true} />
    </ScErrorBlock>
  </main>
);
