import React, { useCallback, useState, FC, useEffect } from 'react';
import { Link } from 'react-navi';
import { useTranslation } from 'react-i18next';

import { useOutsideClickVisibility } from 'hooks/visibility';
import { useTheme } from 'hooks/theme';
import { useApi } from 'hooks/api';

import { showTooltip } from 'helpers/tooltip';
import { getLoggedEmail } from 'helpers/auth';
import { translationFactory } from 'helpers/trans';

import { TooltipTypes } from 'modules/globalTooltip';
import { logout } from 'api/auth';
import { getCurrentUser } from 'api/user';
import { getProfileItems } from './data';
import { UserStatusTag } from 'components/userStatusTag';
import { UserInitials } from 'components/userInitials';
import { Icon } from 'components/UI/Icon';

import cx from 'classnames';
import { ScProfileDropdown } from './styled';

interface ProfileDropdownProps {
  onOpenMenu?: () => void;
  isMobile?: boolean;
}

export const ProfileDropdown: FC<ProfileDropdownProps> = ({
  onOpenMenu,
  isMobile,
}) => {
  const { images, isCrypto, isBSorRC, isWC } = useTheme();
  const { t } = useTranslation('profile');
  const profileItems = getProfileItems(images, t, isCrypto, isBSorRC || isWC);
  const [logoutRequest, { pending }] = useApi(logout);

  async function handleLogoutClick() {
    const { error } = await logoutRequest().request;
    if (error != null) {
      showTooltip({
        type: TooltipTypes.ERROR,
        body: translationFactory('common:error.unknown'),
      });
    } else {
      showTooltip({
        type: TooltipTypes.SUCCESS,
        body: translationFactory('common:header.logoutMsg'),
      });
    }
  }

  const { ref, isComponentVisible, setIsComponentVisible } =
    useOutsideClickVisibility(false);

  const toggle = useCallback(
    () => void setIsComponentVisible((i) => !i),
    [setIsComponentVisible]
  );

  const loggedEmail = getLoggedEmail();

  const [turnover, setTurnover] = useState(0);
  const getUserTurnover = useCallback(async () => {
    const { data: user } = await getCurrentUser();
    setTurnover(user.turnover);
  }, []);

  useEffect(() => {
    getUserTurnover();
  }, [getUserTurnover]);

  return (
    <ScProfileDropdown ref={ref} onClick={toggle} isOpen={isComponentVisible}>
      <div className="header">
        <UserInitials />
        <div>
          <p className="email">
            <span>{loggedEmail}</span>
          </p>
          {turnover != null && <UserStatusTag turnover={turnover} />}
        </div>
        <div className="arrow">
          <Icon name="Arrow" />
        </div>
      </div>
      {(isMobile || isComponentVisible) && (
        <ul className="profile-menu">
          {profileItems.map(({ content, href, image, classNames }, index) => (
            <li key={index} className={cx(classNames)} onClick={onOpenMenu}>
              <Link href={href}>
                {image}
                <span>{content}</span>
              </Link>
            </li>
          ))}
          <li onClick={handleLogoutClick} className={cx(pending && 'disabled')}>
            {t('logOut')}
          </li>
        </ul>
      )}
    </ScProfileDropdown>
  );
};
