// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { ImageMap } from '../contracts';

import account, { ReactComponent as JSXaccount } from './images/account.svg';
import bankCard, { ReactComponent as JSXbankCard } from './images/bankCard.svg';
import bidsHistory, {
  ReactComponent as JSXbidsHistory,
} from './images/bidsHistory.svg';
import bonusIcon, {
  ReactComponent as JSXbonusIcon,
} from './images/bonusIcon.svg';
import bronzeStatus, {
  ReactComponent as JSXbronzeStatus,
} from './images/bronzeStatus.svg';
import checkIcon, {
  ReactComponent as JSXcheckIcon,
} from './images/checkIcon.svg';
import copyIcon, { ReactComponent as JSXcopyIcon } from './images/copyIcon.svg';
import courseIcon, {
  ReactComponent as JSXcourseIcon,
} from './images/courseIcon.svg';
import crossArrowsIcon, {
  ReactComponent as JSXcrossArrowsIcon,
} from './images/crossArrowsIcon.svg';
import crossArrowsIconSecondary, {
  ReactComponent as JSXcrossArrowsIconSecondary,
} from './images/crossArrowsIconSecondary.svg';
import cryptoCheck, {
  ReactComponent as JSXcryptoCheck,
} from './images/cryptoCheck.svg';
import cryptoCheckIcon, {
  ReactComponent as JSXcryptoCheckIcon,
} from './images/cryptoCheckIcon.svg';
import cryptoImage, {
  ReactComponent as JSXcryptoImage,
} from './images/cryptoImage.svg';
import crystals, { ReactComponent as JSXcrystals } from './images/crystals.svg';
import crystalsSuccess, {
  ReactComponent as JSXcrystalsSuccess,
} from './images/crystalsSuccess.svg';
import dashboardIcon, {
  ReactComponent as JSXdashboardIcon,
} from './images/dashboardIcon.svg';
import dashboardPlug, {
  ReactComponent as JSXdashboardPlug,
} from './images/dashboardPlug.svg';
import diamondStatus, {
  ReactComponent as JSXdiamondStatus,
} from './images/diamondStatus.svg';
import discountEmptyIcon, {
  ReactComponent as JSXdiscountEmptyIcon,
} from './images/discountEmptyIcon.svg';
import downArrowIcon, {
  ReactComponent as JSXdownArrowIcon,
} from './images/downArrowIcon.svg';
import errorIcon, {
  ReactComponent as JSXerrorIcon,
} from './images/errorIcon.svg';
import exchangeHistoryIcon, {
  ReactComponent as JSXexchangeHistoryIcon,
} from './images/exchangeHistoryIcon.svg';
import exchangeIcon, {
  ReactComponent as JSXexchangeIcon,
} from './images/exchangeIcon.svg';
import factor1x, { ReactComponent as JSXfactor1x } from './images/factor1x.svg';
import factor2x, { ReactComponent as JSXfactor2x } from './images/factor2x.svg';
import factor3x, { ReactComponent as JSXfactor3x } from './images/factor3x.svg';
import factor4x, { ReactComponent as JSXfactor4x } from './images/factor4x.svg';
import factor5x, { ReactComponent as JSXfactor5x } from './images/factor5x.svg';
import faqIcon, { ReactComponent as JSXfaqIcon } from './images/faqIcon.svg';
import fileIcon, { ReactComponent as JSXfileIcon } from './images/fileIcon.svg';
import fortuneWheel, {
  ReactComponent as JSXfortuneWheel,
} from './images/fortuneWheel.svg';
import giftIcon, { ReactComponent as JSXgiftIcon } from './images/giftIcon.svg';
import goldStatus, {
  ReactComponent as JSXgoldStatus,
} from './images/goldStatus.svg';
import infoIcon, { ReactComponent as JSXinfoIcon } from './images/infoIcon.svg';
import infoIconSecondary, {
  ReactComponent as JSXinfoIconSecondary,
} from './images/infoIconSecondary.svg';
import jivoMobile, {
  ReactComponent as JSXjivoMobile,
} from './images/jivoMobile.svg';
import jivoOff, { ReactComponent as JSXjivoOff } from './images/jivoOff.svg';
import jivoOffEn, {
  ReactComponent as JSXjivoOffEn,
} from './images/jivoOffEn.svg';
import jivoOn, { ReactComponent as JSXjivoOn } from './images/jivoOn.svg';
import jivoOnEn, { ReactComponent as JSXjivoOnEn } from './images/jivoOnEn.svg';
import loader, { ReactComponent as JSXloader } from './images/loader.svg';
import logo, { ReactComponent as JSXlogo } from './images/logo.svg';
import logoMobile, {
  ReactComponent as JSXlogoMobile,
} from './images/logoMobile.svg';
import lottery, { ReactComponent as JSXlottery } from './images/lottery.svg';
import mainBg from './images/mainBg.jpg';
import mainBgMobile from './images/mainBgMobile.jpg';
import notificationBonuses, {
  ReactComponent as JSXnotificationBonuses,
} from './images/notificationBonuses.svg';
import notificationCrypto, {
  ReactComponent as JSXnotificationCrypto,
} from './images/notificationCrypto.svg';
import notificationGiveaway, {
  ReactComponent as JSXnotificationGiveaway,
} from './images/notificationGiveaway.svg';
import notificationSystem, {
  ReactComponent as JSXnotificationSystem,
} from './images/notificationSystem.svg';
import notificationTelegram, {
  ReactComponent as JSXnotificationTelegram,
} from './images/notificationTelegram.svg';
import notoficationSystem, {
  ReactComponent as JSXnotoficationSystem,
} from './images/notoficationSystem.svg';
import partnersIcon, {
  ReactComponent as JSXpartnersIcon,
} from './images/partnersIcon.svg';
import partnersPlug, {
  ReactComponent as JSXpartnersPlug,
} from './images/partnersPlug.svg';
import piggyIcon, {
  ReactComponent as JSXpiggyIcon,
} from './images/piggyIcon.svg';
import platinumStatus, {
  ReactComponent as JSXplatinumStatus,
} from './images/platinumStatus.svg';
import profileIcon, {
  ReactComponent as JSXprofileIcon,
} from './images/profileIcon.svg';
import requestIcon, {
  ReactComponent as JSXrequestIcon,
} from './images/requestIcon.svg';
import securityIcon, {
  ReactComponent as JSXsecurityIcon,
} from './images/securityIcon.svg';
import settingsIcon, {
  ReactComponent as JSXsettingsIcon,
} from './images/settingsIcon.svg';
import settingsPlug, {
  ReactComponent as JSXsettingsPlug,
} from './images/settingsPlug.svg';
import silverStatus, {
  ReactComponent as JSXsilverStatus,
} from './images/silverStatus.svg';
import starDollarIcon, {
  ReactComponent as JSXstarDollarIcon,
} from './images/starDollarIcon.svg';
import starDollarGrayIcon, {
  ReactComponent as JSXstarDollarGrayIcon,
} from './images/starDollarGrayIcon.svg';
import starDollarInvertedIcon, {
  ReactComponent as JSXstarDollarInvertedIcon,
} from './images/starDollarInvertedIcon.svg';
import successIcon, {
  ReactComponent as JSXsuccessIcon,
} from './images/successIcon.svg';
import withdrawSuccess, {
  ReactComponent as JSXwithdrawSuccess,
} from './images/withdrawSuccess.svg';
import robotIcon, {
  ReactComponent as JSXrobotIcon,
} from './images/robotIcon.svg';

export const images: ImageMap = {
  account: { path: account, component: JSXaccount },
  bankCard: { path: bankCard, component: JSXbankCard },
  bidsHistory: { path: bidsHistory, component: JSXbidsHistory },
  bonusIcon: { path: bonusIcon, component: JSXbonusIcon },
  bronzeStatus: { path: bronzeStatus, component: JSXbronzeStatus },
  checkIcon: { path: checkIcon, component: JSXcheckIcon },
  copyIcon: { path: copyIcon, component: JSXcopyIcon },
  courseIcon: { path: courseIcon, component: JSXcourseIcon },
  crossArrowsIcon: { path: crossArrowsIcon, component: JSXcrossArrowsIcon },
  crossArrowsIconSecondary: {
    path: crossArrowsIconSecondary,
    component: JSXcrossArrowsIconSecondary,
  },
  cryptoCheck: { path: cryptoCheck, component: JSXcryptoCheck },
  cryptoCheckIcon: { path: cryptoCheckIcon, component: JSXcryptoCheckIcon },
  cryptoImage: { path: cryptoImage, component: JSXcryptoImage },
  crystals: { path: crystals, component: JSXcrystals },
  crystalsSuccess: { path: crystalsSuccess, component: JSXcrystalsSuccess },
  dashboardIcon: { path: dashboardIcon, component: JSXdashboardIcon },
  dashboardPlug: { path: dashboardPlug, component: JSXdashboardPlug },
  diamondStatus: { path: diamondStatus, component: JSXdiamondStatus },
  discountEmptyIcon: {
    path: discountEmptyIcon,
    component: JSXdiscountEmptyIcon,
  },
  downArrowIcon: { path: downArrowIcon, component: JSXdownArrowIcon },
  errorIcon: { path: errorIcon, component: JSXerrorIcon },
  exchangeHistoryIcon: {
    path: exchangeHistoryIcon,
    component: JSXexchangeHistoryIcon,
  },
  exchangeIcon: { path: exchangeIcon, component: JSXexchangeIcon },
  factor1x: { path: factor1x, component: JSXfactor1x },
  factor2x: { path: factor2x, component: JSXfactor2x },
  factor3x: { path: factor3x, component: JSXfactor3x },
  factor4x: { path: factor4x, component: JSXfactor4x },
  factor5x: { path: factor5x, component: JSXfactor5x },
  faqIcon: { path: faqIcon, component: JSXfaqIcon },
  fileIcon: { path: fileIcon, component: JSXfileIcon },
  fortuneWheel: { path: fortuneWheel, component: JSXfortuneWheel },
  giftIcon: { path: giftIcon, component: JSXgiftIcon },
  goldStatus: { path: goldStatus, component: JSXgoldStatus },
  infoIcon: { path: infoIcon, component: JSXinfoIcon },
  infoIconSecondary: {
    path: infoIconSecondary,
    component: JSXinfoIconSecondary,
  },
  jivoMobile: { path: jivoMobile, component: JSXjivoMobile },
  jivoOff: { path: jivoOff, component: JSXjivoOff },
  jivoOffEn: { path: jivoOffEn, component: JSXjivoOffEn },
  jivoOn: { path: jivoOn, component: JSXjivoOn },
  jivoOnEn: { path: jivoOnEn, component: JSXjivoOnEn },
  loader: { path: loader, component: JSXloader },
  logo: { path: logo, component: JSXlogo },
  logoMobile: { path: logoMobile, component: JSXlogoMobile },
  lottery: { path: lottery, component: JSXlottery },
  mainBg: { path: mainBg, component: React.Fragment },
  mainBgMobile: { path: mainBgMobile, component: React.Fragment },
  notificationBonuses: {
    path: notificationBonuses,
    component: JSXnotificationBonuses,
  },
  notificationCrypto: {
    path: notificationCrypto,
    component: JSXnotificationCrypto,
  },
  notificationGiveaway: {
    path: notificationGiveaway,
    component: JSXnotificationGiveaway,
  },
  notificationSystem: {
    path: notificationSystem,
    component: JSXnotificationSystem,
  },
  notificationTelegram: {
    path: notificationTelegram,
    component: JSXnotificationTelegram,
  },
  notoficationSystem: {
    path: notoficationSystem,
    component: JSXnotoficationSystem,
  },
  partnersIcon: { path: partnersIcon, component: JSXpartnersIcon },
  partnersPlug: { path: partnersPlug, component: JSXpartnersPlug },
  piggyIcon: { path: piggyIcon, component: JSXpiggyIcon },
  platinumStatus: { path: platinumStatus, component: JSXplatinumStatus },
  profileIcon: { path: profileIcon, component: JSXprofileIcon },
  requestIcon: { path: requestIcon, component: JSXrequestIcon },
  securityIcon: { path: securityIcon, component: JSXsecurityIcon },
  settingsIcon: { path: settingsIcon, component: JSXsettingsIcon },
  settingsPlug: { path: settingsPlug, component: JSXsettingsPlug },
  silverStatus: { path: silverStatus, component: JSXsilverStatus },
  starDollarIcon: { path: starDollarIcon, component: JSXstarDollarIcon },
  starDollarGrayIcon: {
    path: starDollarGrayIcon,
    component: JSXstarDollarGrayIcon,
  },
  starDollarInvertedIcon: {
    path: starDollarInvertedIcon,
    component: JSXstarDollarInvertedIcon,
  },
  successIcon: { path: successIcon, component: JSXsuccessIcon },
  withdrawSuccess: { path: withdrawSuccess, component: JSXwithdrawSuccess },
  robotIcon: { path: robotIcon, component: JSXrobotIcon },
};
