import React from 'react';

import { getLoggedEmail } from 'helpers/auth';
import { ScUserInitials } from './styled';

export const UserInitials = () => {
  const loggedEmail = getLoggedEmail();
  const avatarLetters = loggedEmail && loggedEmail.slice(0, 2).toUpperCase();

  return <ScUserInitials>{avatarLetters}</ScUserInitials>;
};
