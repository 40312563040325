export function isObject(o: any) {
  return o === Object(o) && !Array.isArray(o) && typeof o !== 'function';
}

export function isPromise<T = any>(o: any): o is Promise<T> {
  return isObject(o) && typeof o.then === 'function';
}

export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function prettyNumber(n: number) {
  const absNum = Math.abs(n);

  if (absNum < 1e3) {
    return n.toString();
  }

  const strNum = absNum.toString();

  const prettify = (s: string) => s.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  let pretty;
  if (strNum.includes('.')) {
    const [integer, fraction] = strNum.split('.', 2);

    pretty = prettify(integer) + (fraction === '' ? '' : '.' + fraction);
  } else {
    pretty = prettify(strNum);
  }

  return (n < 0 ? '-' : '') + pretty;
}

export function numberFixed(
  n: number,
  digits: number = 0,
  withoutRound?: boolean
) {
  if (withoutRound) {
    const [integer, fraction] = n.toString().split('.');
    if (fraction == null || fraction.length < digits) {
      return n;
    } else {
      return parseFloat(`${integer}.${fraction.slice(0, digits)}`);
    }
  }
  return parseFloat(n.toFixed(digits));
}

export function replaceAll(str: string, search: string, replacement: string) {
  return str.split(search).join(replacement);
}

export function difference<T>(setA: Set<T>, setB: Set<T>) {
  let _difference = new Set(setA);

  setB.forEach((elem) => _difference.delete(elem));

  return _difference;
}

export interface DiffOptions<T> {
  equalityFn?: (a: any, b: any, key: keyof T) => boolean;
  missing?: any;
}

export function getShallowDiff<T extends { [key: string]: any }>(
  changed: T,
  initial: T,
  options?: DiffOptions<T>
): Partial<T> {
  const equalityFn = (options && options.equalityFn) || ((a, b) => a === b);

  const diff = Object.keys(changed).reduce<{ [key: string]: any }>(
    (previous, key) => {
      const changedValue = changed[key];
      const initialValue = initial[key];

      if (!equalityFn(initialValue, changedValue, key)) {
        previous[key] = changedValue;
      }

      return previous;
    },
    {}
  );

  if (options && typeof options.missing !== 'undefined') {
    const diffKeys = difference(
      new Set(Object.keys(initial)),
      new Set(Object.keys(changed))
    );

    diffKeys.forEach((key) => {
      if (initial[key] !== options.missing) diff[key] = options.missing;
    });
  }

  return diff as any;
}

export function isEmpty(obj: object) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function generateRandomString(
  len: number,
  choices: string = '0123456789qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM'
) {
  let result = '';

  const choicesLen = choices.length;

  for (let i = 0; i < len; i++) {
    result += choices.charAt(Math.floor(Math.random() * choicesLen));
  }
  return result;
}

export function entries<T extends { [key: string]: any }>(
  obj: T
): [string, T[keyof T]][] {
  let ownProps = Object.keys(obj),
    i = ownProps.length,
    resArray = new Array(i); // preallocate the Array
  while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

  return resArray;
}

export function convertHexToRgb(hex: string, alpha?: number) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`;
}

export const toCapitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const camelToCapitalize = (s: string) => {
  return toCapitalize(s.replace(/[\w]([A-Z])/g, (m) => m[0] + ' ' + m[1]));
};

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export function insert<T>(arr: T[], index: number, newItem: T) {
  return [...arr.slice(0, index), newItem, ...arr.slice(index)];
}

export function hideParamsFromUrl(
  url: string,
  marker: string,
  masks: string[]
) {
  let urlUpdate = url;

  for (let i = 0; i < masks.length; i++) {
    const strRegExp =
      masks[i].replace(/\//g, '\\/').replace(marker, '([0-9A-Za-z]*)') +
      '(\\/*)?(\\?.*)?$';
    const regExp = new RegExp(strRegExp);

    const matchResult = url?.match(regExp);

    if (matchResult != null && matchResult[1] != null) {
      urlUpdate = url.replace(matchResult[1], marker);
      break;
    }
  }

  return urlUpdate;
}

export const declOfNum = (number: number, titles: string[]) => {
  number = Math.abs(number);
  if (Number.isInteger(number)) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ];
  }
  return titles[1];
};

export const formatNumber = (value: string) => {
  const stringNumber = replaceAll(value, ' ', '');
  const number = Number(stringNumber);
  return !isNaN(number) ? number : 0;
};

function isStringRegExp(maskElement: string) {
  const firstChar = maskElement.charAt(0);
  const lastChar = maskElement.charAt(maskElement.length - 1);

  if (firstChar === '/' && lastChar === '/') {
    return maskElement;
  }
}

export function createMaskRegExp(maskArray: string[]) {
  return maskArray.map((item: string) => {
    return isStringRegExp(item) ? new RegExp(item.slice(1, -1)) : item;
  });
}
