import React from 'react';

import { ScTag } from './styled';
import { Icon } from 'components/UI/Icon';

export const Tag = () => {
  return (
    <ScTag>
      <Icon name="TagNewIcon" />
    </ScTag>
  );
};
