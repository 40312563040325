import { factory } from './base';
import { snakify } from '../helpers/api';
import { shouldMock, mockRequestWith } from './mocks';
import { MOCKS } from './mocks.bonus';

export interface BonusRanges {
  id: number;
  sumFrom: number;
  sumTo: number;
  coefficient: number;
}

export enum BonusRangesName {
  BRONZE = 'bronze',
  SILVER = 'silver',
  GOLD = 'gold',
  PLATINUM = 'platinum',
  DIAMOND = 'diamond',
}

export const getBonusRanges = factory((r) => () => {
  if (shouldMock()) {
    return mockRequestWith(MOCKS['/bonus/rangs']);
  }
  return r<BonusRanges[]>('get', '/bonus/rangs');
});

export enum OperationType {
  INCOME,
  WITHDRAW,
}

export enum WithdrawStatus {
  IN_PROCESS,
  SUCCESS,
  REJECTED,
}

export interface BonusOperation {
  type: OperationType;
  manual: boolean;
  id: number;
  amount: number;
  balance: number;
  date: string;
  crystals?: number;
  withdraw?: {
    id: number;
    currency: string;
    amount: number;
    status: WithdrawStatus;
  };
  bid?: {
    id: number;
    invoice: {
      id: number;
      currency: string;
      amount: number;
    };
    withdraw: {
      id: number;
      currency: string;
      amount: number;
    };
  };
}

export const getBonusesOperations = factory(
  (r) => (limit: number, last_id?: number) => {
    if (shouldMock()) {
      return mockRequestWith(MOCKS['/bonus/operations']);
    }
    return r<BonusOperation[]>('get', '/bonus/operations', { limit, last_id });
  }
);

export interface BuyCrystalsParams {
  amount: number;
}

export enum BuyCrystalsError {
  BONUS_BALANCE_EXCEEDED,
}

export const buyCrystals = factory(
  (r) => (params: BuyCrystalsParams) =>
    r<null>('post', '/bonus/crystals', snakify(params))
);

export interface PayWay {
  id: number;
  name: string;
  currency: string;
  exchRate: number;
  min: number;
  max: number;
  fields: {
    name: string;
    title: { [lang: string]: string } | string;
    dataRegex?: string;
    example?: string;
    mask?: string[];
  }[];
}

export const getPayWays = factory(
  (r) => (lang: string) => r<PayWay[]>('get', '/bonus/methods', { lang })
);

export enum BonusWithdrawError {
  INVALID_ID,
  INVALID_AMOUNT,
  BONUS_BALANCE_EXCEEDED,
  INVALID_ACCOUNT,
}

export interface BonusWithdrawParams {
  payway: number;
  amount: number;
  fields: { [name: string]: string };
}

export const bonusWithdraw = factory(
  (r) => (params: BonusWithdrawParams) =>
    r<null>('post', '/bonus/withdraw', snakify(params))
);
