export type Subscriber = (isAuthenticated: boolean) => void;

export class AuthService {
  public isAuthenticated: boolean;
  private subscribers: Subscriber[];

  constructor(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
    this.subscribers = [];
  }

  private notify = () => {
    this.subscribers.forEach((subscriber) => subscriber(this.isAuthenticated));
  };

  login = () => {
    this.setIsAuthenticated(true);
  };

  logout = () => {
    this.setIsAuthenticated(false);
  };

  setIsAuthenticated = (isAuthenticated: boolean) => {
    this.isAuthenticated = isAuthenticated;
    this.notify();
  };

  subscribe = (subscriber: Subscriber) => {
    this.subscribers.push(subscriber);
    return () => {
      const subscriberIndex = this.subscribers.findIndex(
        (c) => c === subscriber
      );
      if (subscriberIndex === -1) return;
      this.subscribers.splice(subscriberIndex, 1);
    };
  };
}
