import { keyframes } from 'styled-components';

export const show = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1;}
`;

export const hide = keyframes`
  0% {opacity: 1;}
  100% {opacity: 0;}
`;

export const dropDownShow = keyframes`
  0% {
    transform: translateY(-55px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const globalNotificationShow = keyframes`
0%{
  transform: translateX(80%) scale(1.05);
  opacity: 0;
}
80%{
  transform: translateX(0) scale(1.05);
  opacity: 1;
}
100%{
  transform: scale(1);
}
`;

export const accordionItemShow = keyframes`
0%{
  height: 0;
  opacity: 0;
}
100%{
  height: 100%;
  opacity: 1;
}
`;

export const loading = keyframes`
0%{
  width: 1%;
}
100%{
  width: 100%;
}
`;

export const jivoAnimation = keyframes`
  0% {
    transform: translateX(49px);
}
  100% {
    transform: translateX(-3px);
}
`;
