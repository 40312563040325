import React, { useEffect } from 'react';
import { useLoadingRoute } from 'react-navi';
// @ts-ignore
import nprogress from 'nprogress/nprogress.js';

import { ScProgressBar } from 'components/progressBar/styled';

export const ProgressBar = () => {
  const routeLoading = Boolean(useLoadingRoute());

  useEffect(() => {
    nprogress.configure({
      parent: '#progressBar',
      easing: 'ease-in-out',
      speed: 400,
      showSpinner: false,
    });

    return () => nprogress.remove();
  }, []);

  useEffect(() => {
    if (routeLoading) {
      nprogress.start();

      const interval = setInterval(() => {
        nprogress.inc(0.3 * Math.cos(nprogress.status * (Math.PI / 2)));
      }, 300);

      return () => clearInterval(interval);
    } else {
      nprogress.done();
    }
  }, [routeLoading]);

  return <ScProgressBar id="progressBar" />;
};
