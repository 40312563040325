import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ScScheduleBlock } from './styled';
import { useTheme } from 'hooks/theme';
import { Icon } from 'components/UI/Icon';

export const ScheduleBlock = forwardRef<HTMLDivElement>(() => {
  const { t } = useTranslation('common');
  const { images } = useTheme();

  return (
    <ScScheduleBlock>
      <p className="item">
        <images.robotIcon.component />
        <span>
          {t(`common:header.${process.env.REACT_APP_THEME}.autoExchange`, {
            defaultValue: t('common:header.autoExchange'),
          })}
        </span>
      </p>
      <p className="item">
        <Icon name="ScheduleIcon" />
        <span>
          {t(`common:header.${process.env.REACT_APP_THEME}.support`, {
            defaultValue: t('common:header.support'),
          })}
        </span>
      </p>
    </ScScheduleBlock>
  );
});
