import styled from 'styled-components/macro';
import { ScLangToggle } from 'components/langToggle/styled';

export const ScFooter = styled.footer`
  padding: 28px 0;
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    padding: 0 14px;
    ${ScLangToggle} {
      margin-bottom: 16px;
    }
  }
  .aml-link {
    font: 300 14px / 16px ${({ theme }) => theme.mainFont};
    color: ${({ theme }) => theme.mainTextColor};
  }
  .flex {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .bestchange-link {
      position: absolute;
      left: 56%;
      margin: 4px auto;
    }
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      flex-direction: column-reverse !important;
      align-items: flex-start;
      & .bestchange-link {
        position: static;
        margin: 4px 0;
      }
    }
  }
  .copyright {
    color: ${({ theme }) => theme.mainTextColor};
    font: normal 14px / 16px ${({ theme }) => theme.mainFont};
    ${({ theme }) =>
      theme.isWC &&
      `
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    `}
  }
  .links {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      flex-direction: column;
      justify-content: initial;
      margin: 16px 0;
      a {
        display: block;
        margin-bottom: 16px;
      }
    }
    a {
      font: 300 14px / 16px ${({ theme }) => theme.mainFont};
      ${({ theme }) =>
        theme.isWC &&
        `
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      `}
      color: ${({ theme }) => theme.mainTextColor};
    }
    .left a {
      text-decoration: underline;
    }
    .bestchange-link {
      display: flex;
      align-items: center;
      & > span {
        margin-right: 8px;
        white-space: nowrap;
      }
      & > svg {
        width: 77px;
      }
    }
    .left {
      align-items: baseline;
    }
    .right {
      align-items: center;
    }
    .left,
    .right {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      a:not(:last-child) {
        margin-right: 2em;
      }
    }
  }
`;
