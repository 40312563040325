import React, { FC, ReactNode, useEffect, useState } from 'react';
import { NotFoundBoundary, useCurrentRoute } from 'react-navi';
import { useTranslation } from 'react-i18next';
import { Severity } from '@sentry/types';

import { Sidebar } from 'components/sidebar';
import { Footer } from 'components/footer';
import { Header } from 'components/header';
import { NotFound } from 'components/notFound';
import { ScBlockLoading, ScContent } from 'components/block/styled';

import { ErrorBoundary } from 'components/internal/errorBoundary';
import { ErrorBlock } from 'components/block';

import { GlobalTooltip } from 'modules/globalTooltip';
import { useTheme } from 'hooks/theme';
import { loadNamespaces } from 'helpers/i18n';
import { ProgressBar } from 'components/progressBar';
import { NotificationPopup } from 'components/notificationPopup';

const DEFAULT_TRANSLATION_NAMESPACES = ['common'];
const DEFAULT_TRANSLATION_LOADING_TIMEOUT = 15e3;

const isEOF = process.env.REACT_APP_API_EOL;

function useDefaultTranslationNamespacesPreLoading() {
  const [loadingState, setLoadingState] = useState({
    isLoading: true,
    isInError: false,
  });

  useEffect(() => {
    let unmounted = false;

    (async () => {
      try {
        await new Promise((resolve, reject) => {
          loadNamespaces(DEFAULT_TRANSLATION_NAMESPACES, resolve);

          setTimeout(reject, DEFAULT_TRANSLATION_LOADING_TIMEOUT);
        });

        if (unmounted) return;

        setLoadingState({ isLoading: false, isInError: false });
      } catch (e) {
        if (unmounted) return;

        setLoadingState({ isLoading: false, isInError: true });
      }
    })();

    return () => void (unmounted = true);
  }, []);

  return loadingState;
}

const Title: FC = () => {
  const { title } = useCurrentRoute();

  const { defaultTitle } = useTheme();

  const { t } = useTranslation();

  useEffect(() => {
    if (typeof title === 'function') {
      document.title = title(t);
    } else if (title != null) {
      document.title = title;
    } else {
      document.title = defaultTitle;
    }
  }, [title, defaultTitle, t]);

  return null;
};

export const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  const { isLoading, isInError } = useDefaultTranslationNamespacesPreLoading();

  if (isInError) {
    return <p>error loading</p>;
  }

  if (isLoading) {
    return (
      <main>
        <ScBlockLoading />
      </main>
    );
  }

  return (
    <main>
      <Title />

      <ProgressBar />

      <Header />

      <section>
        <Sidebar />

        <ScContent>
          <ErrorBoundary fallback={<ErrorBlock />} severity={Severity.Critical}>
            <NotFoundBoundary render={NotFound}>{children}</NotFoundBoundary>
          </ErrorBoundary>
        </ScContent>
      </section>

      <Footer />

      <GlobalTooltip />
      {!isEOF && <NotificationPopup />}
    </main>
  );
};
