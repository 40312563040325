import * as React from 'react';
import { SVGProps } from 'react';

const SvgExclamationIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 27.5c6.9 0 12.5-5.6 12.5-12.5S21.9 2.5 15 2.5 2.5 8.1 2.5 15 8.1 27.5 15 27.5ZM13.75 8.75h2.5v7.5h-2.5v-7.5Zm0 10h2.5v2.5h-2.5v-2.5Z"
        fill="#FF822E"
      />
    </svg>
  );
};

export default SvgExclamationIcon;
