import i18next, { i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector, {
  DetectorOptions,
} from 'i18next-browser-languagedetector';
import * as Sentry from '@sentry/react';
import { ENV } from '../env/env.config';

function getAvailableLng(theme?: string) {
  switch (theme) {
    case 'bitstore':
    case 'rocketchange':
    case 'flychange':
    case 'whitechanger':
      return ['ru'];
    default:
      return ['ru', 'en'];
  }
}

export const availableLng = getAvailableLng(ENV.APP_THEME);

export const fallbackLng = 'ru';

class CustomLanguageDetector extends LanguageDetector {
  detect(detectionOrder: DetectorOptions['order']) {
    return availableLng.length > 1
      ? super.detect(detectionOrder) || fallbackLng
      : fallbackLng;
  }
}

let i18nInstance: i18n;

const I18nInstanceSetter = {
  type: '3rdParty' as const,

  init(instance: i18n) {
    i18nInstance = instance;
  },
};

export { i18nInstance };

i18next.on('languageChanged', (lng) => {
  document.documentElement.lang = lng;
  Sentry.addBreadcrumb({
    category: 'localization',
    message: `Language was changed to ${lng}`,
    level: Sentry.Severity.Info,
  });

  Sentry.setTag('lng', lng);
});

i18next.on('initialized', function () {
  Sentry.addBreadcrumb({
    category: 'localization',
    message: `Localization was initialized`,
    level: Sentry.Severity.Info,
  });
});

i18next.on('failedLoading', function (lng, ns, msg) {
  Sentry.captureMessage(
    `Failed to load i18n resource ${lng}.${ns}\nMsg: ${msg}`,
    Sentry.Severity.Warning
  );
});

export default i18next
  .use(CustomLanguageDetector)
  .use(XHR)
  .use(I18nInstanceSetter)
  .use(initReactI18next)
  .init({
    fallbackLng,
    backend: {
      loadPath: `${
        !ENV.IS_DEV && ENV.PUBLIC_URL ? ENV.PUBLIC_URL : ''
      }/locales/{{lng}}/{{ns}}.json`,
      queryStringParams: { v: ENV.BUILD_VERSION },
    },
    detection: {
      order: ['localStorage', 'querystring', 'cookie', 'header'],
      lookupLocalStorage: 'lng',
      caches: ['localStorage', 'cookie'],
    },
    ns: ['common'],
    defaultNS: 'common',
    debug: false, // process.env.REACT_APP_ENV !== 'production',
    react: { useSuspense: false },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
